import React, { useState, useEffect } from "react";
import { getAnaSayfaItems } from "../services/commonService";
import { useDispatch, useSelector } from "react-redux";
import SwiperUrunGrubus from "../components/SwiperUrunGrubus";
import SwiperUruns from "../components/SwiperUruns";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const common = useSelector((state) => state.common);
  const [urunGrubus, setUrunGrubus] = useState([]);
  const [enCokSatans, setEnCokSatans] = useState([]);
  const [yeniUruns, setYeniUruns] = useState([]);
  useEffect(() => {
    dispatch(getAnaSayfaItems()).then((data) => {
      setUrunGrubus([]);
      setUrunGrubus(data.data.urunGrubs);
      setYeniUruns([]);
      setYeniUruns(data.data.yeniUruns);
      setEnCokSatans([]);
      setEnCokSatans(data.data.enCokSatan);
    });
  }, [dispatch, common.selectedDilItems]);

  return (
    <div className="mainUstContainer">
      <div className="mainLeftMenuBtn">
        <button
          className="solUstBtn btn btn-default"
          style={{ borderWidth: 0, padding: 0 }}
          onClick={() => {
            history.push("/islemler");
          }}
        >
          <i className="bi bi-list solUst"></i>
        </button>
      </div>
      <div className="mainRightSearchBtn">
        <input
          className="form-control araText"
          style={{ height: 43, paddingLeft: 30 }}
          // placeholder= "Yemek Ara"
          placeholder={common.selectedDilItems.urunAraTxt}
          onClick={() => {
            history.push("/urunler/0/true");
          }}
        />
        <i
          className="bi bi-search"
          style={{ position: "relative", top: -33, marginLeft: 7 }}
        ></i>
      </div>

      <div className="row" style={{ width: "100%", marginLeft: 0 }}>
        <div
          className="col-md-12"
          style={{
            marginTop: 30,
          }}
        >
          <div
            style={{ display: "inline-block", width: "100%", marginBottom: 20 }}
          >
            <h5 style={{ float: "left", fontWeight: "bold" }}>
              {/* Ürün Kategorileri */}
              {common.selectedDilItems.urunKategorileriTxt}
            </h5>
            <a
              className="mainKatHepsiniGor"
              onClick={() => history.push("/kategoriler")}
            >
              {/* Hepsini Gör  */}
              {common.selectedDilItems.hepsiniGorTxt}
            </a>
          </div>
        </div>

        <div className="col-md-12">
          {urunGrubus.length > 0 && (
            <SwiperUrunGrubus data={urunGrubus} id={"urunGrubus"}  />
          )}
        </div>
        <div
          className="col-md-12 anaSayfaSwiper"
          style={{
            marginTop: 20,
          }}
        >
          <div
            style={{ display: "inline-block", width: "100%", marginBottom: 20 }}
          >
            <h5 style={{ float: "left", fontWeight: "bold" }}>
              {/* Önerilenler */}
              {common.selectedDilItems.onerilenlerTxt}
            </h5>
            <a
              className="mainKatHepsiniGor"
              onClick={() => history.push("/urunler/0")}
            >
              {/* Hepsini Gör */}
              {common.selectedDilItems.hepsiniGorTxt}
            </a>
          </div>
        </div>
        <div className="col-md-12 ">
          {enCokSatans.length > 0 && (
            <SwiperUruns data={enCokSatans} id={"urunEnCokSatan"} />
          )}
        </div>
        <div
          className="col-md-12 anaSayfaSwiper"
          style={{
            marginTop: 20,
          }}
        >
          <div
            style={{ display: "inline-block", width: "100%", marginBottom: 20 }}
          >
            <h5 style={{ float: "left", fontWeight: "bold" }}>
              {/* Yeni Lezzetler */}
              {common.selectedDilItems.yeniLezzetlerTxt}
            </h5>
            <a
              className="mainKatHepsiniGor"
              onClick={() => history.push("/urunler/0")}
            >
              {/* Hepsini Gör */}
              {common.selectedDilItems.hepsiniGorTxt}
            </a>
          </div>
        </div>
        <div className="col-md-12">
          {yeniUruns.length > 0 && (
            <SwiperUruns data={yeniUruns} id={"urunYeniuruns"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
