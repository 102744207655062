import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import useWindowDimensions from "../components/windowDimensions";
import { useHistory, Link, useParams } from "react-router-dom";
import { userLoggedOut } from "../store/reducers/userSlice";
function Profil() {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);

  useEffect(() => {
    if (!user.accessToken) {
      history.push("/login");
    }
  }, []);
  function logout() {
    dispatch(userLoggedOut());
    window.localStorage.removeItem("selectedUruns");
    window.localStorage.removeItem("masaInfo");
    window.localStorage.removeItem("jwtToken");
    history.push("/");
  }
  return (
    <div
      style={{
        width: "90%",
        marginLeft: "5%",
        backgroundColor: "#fff",
        minHeight: height - 100,
      }}
    >
      <div className="row" style={{ marginTop: 20, padding: 15 }}>
        <div className="col-md-12">
          <h5>
            {user.userData.adi} {user.userData.soyadi}
          </h5>
          <h5 style={{ color: "#ddd" }}>{user.userData.telefon}</h5>

          <div
            className="islemlerDiv"
            style={{
              width: "100%",
              paddingBottom: 10,
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            <Button
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                borderWidth: 0,
                width: "100%",
                color: "#000",
              }}
              color="secondary"
              onClick={() => history.push("/profiledit")}
            >
              <i
                class="bi bi-pencil"
                style={{ fontSize: 20, marginRight: 10 }}
              ></i>{" "}
              {/* Güncelle */}
              {common.selectedDilItems.guncelleBtnTxt}
            </Button>
          </div>
          <div
            className="islemlerDiv"
            style={{
              width: "100%",
              paddingBottom: 10,
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            <Button
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                borderWidth: 0,
                width: "100%",
                color: "#000",
              }}
              color="secondary"
              onClick={() => logout()}
            >
              <i
                class="bi bi-box-arrow-left"
                style={{ fontSize: 20, marginRight: 10 }}
              ></i>{" "}
              {/* Çıkış */}
              {common.selectedDilItems.cikisBtnTxt}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profil;
