import React from "react";

const alertStyle = {
  backgroundColor: "rgb(255 250 250)",
  color: "#060606",
  padding: "10px",
  //   textTransform: "uppercase",
  borderRadius: "3px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "rgb(0 0 0 / 23%) 0px 2px 2px 3px",
  fontFamily: "Arial",
  width: "300px",
  boxSizing: "border-box",
  zIndex: 2,
  borderRadius: 10,
};

const buttonStyle = {
  marginLeft: "20px",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  color: "#0000 !important",
};

const BaseIcon = ({ color, pushRight = true, children }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ marginRight: pushRight ? "20px" : "0", minWidth: 24 }}
  >
    {children}
  </svg>
);

const CloseIcon = () => (
  <BaseIcon color="#0000" pushRight={false}>
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </BaseIcon>
);

const ErrorIcon = () => (
  <BaseIcon color="#FF0040">
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="12" />
    <line x1="12" y1="16" x2="12" y2="16" />
  </BaseIcon>
);

const InfoIcon = () => (
  <BaseIcon color="#2E9AFE">
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="16" x2="12" y2="12" />
    <line x1="12" y1="8" x2="12" y2="8" />
  </BaseIcon>
);

const SuccessIcon = () => (
  <BaseIcon color="#31B404">
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <polyline points="22 4 12 14.01 9 11.01" />
  </BaseIcon>
);

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <>
      <div style={{ ...alertStyle, ...style }}>
        {options.type === "info" && <InfoIcon />}
        {options.type === "success" && <SuccessIcon />}
        {options.type === "error" && <ErrorIcon />}
        <span style={{ flex: 2, fontSize: 12 }}>
          <p
            style={{
              fontSize: 15,
              color: "#f6192a",
              marginBottom: 3,
              fontWeight: "600",
            }}
          >
            Voswos Cafe Bistro
          </p>
          {message}
        </span>
        <button onClick={close} style={buttonStyle}>
          {/* <CloseIcon /> */}
          <i
            class="bi bi-x-circle"
            style={{ fontSize: 20, color: "#f6192af0" }}
          ></i>
        </button>
      </div>
    </>
  );
};

export default AlertTemplate;
