import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import { setMasaIdAndNo } from "../store/reducers/commonSlice";
import { getQrDogrula } from "../store/reducers/commonSlice";
import { useAlert } from "react-alert";

function QrCodeDogrula(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const alert = useAlert();
  const [durum, setDurum] = useState(1);

  useEffect(() => {
    dispatch(getQrDogrula(props.qrvalue)).then((result) => {
      if (result.result) {
        let masaInfo = {
          masaId: result.data.masaId,
          masaAdi: result.data.masaNo,
          eklenmeTarihi: new Date(),
        };
        window.localStorage.setItem("masaInfo", JSON.stringify(masaInfo));
        dispatch(setMasaIdAndNo(masaInfo));
        history.push("/");
      } else {
        setDurum(0);
        alert.show(common.selectedDilItems.qrDogrulanmadiTxt, {
          type: "error",
          timeout: 2000,
        });
      }
    });
  }, []);
  if (durum == 0) {
    return (
      <div className="col-md-12" style={{ textAlign: "center" }}>
        <h5>{common.selectedDilItems.qrDogrulanmadiTxt}</h5>
      </div>
    );
  }
  if (durum == 1) {
    return (
      <div className="col-md-12">
        <div className="splashLoader" style={{ position: "absolute", top: 60 }}>
          <Spinner
            animation="border"
            variant="light"
            style={{ color: "rgb(246 25 42)" }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#f6192a",
            height: window.innerHeight - 1 - 25,
          }}
        ></div>
      </>
    );
  }
}
export default QrCodeDogrula;
