import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUrunList,
  setYeniSiparis,
  getSiparislerim,
  setSiparisIptal,
} from "../store/reducers/urunSlice";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import _, { result } from "lodash";
import { formatDateTime } from "../components/DateTime";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import useWindowDimensions from "../components/windowDimensions";
import { useParams } from "react-router-dom";
import { formatDateTimeServer } from "../components/DateTime";

function Siparisler() {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const urun = useSelector((state) => state.urun);
  const { width, height } = useWindowDimensions();
  const routeParams = useParams();
  const [adisyonBilgileri, setAdisyonBilgileri] = useState(urun.urunList);
  const [toplamTutar, setToplamTutar] = useState();
  const [bilgilerGeldi, setBilgilerGeldi] = useState(false);

  const [silModalOpen, setSilModalOpen] = useState(false);
  const toggle = () => setSilModalOpen(!silModalOpen);
  const [selectedSipIndex, setSelectedSipIndex] = useState(0);

  const [selectedTab, setSelectedTab] = useState(1);
  const [processing, setProcessing] = useState(false);

  const [siparislerim, setSiparislerim] = useState([]);
  const [iptalSipId, setIptalSipId] = useState(0);
  const [toplamBorc, setToplamBorc] = useState(0);
  const toggleSepeteEkle = () => setModalSepeteEkle(!modalSepeteEkle);
  const [modalSepeteEkle, setModalSepeteEkle] = useState(false);

  const [otomatikSiparisVer, setOtomatikSiparisVer] = useState(false);
  useEffect(() => {
    getSiparisler();
  }, [dispatch]);

  useEffect(() => {
    let toplamT = 0;
    adisyonBilgileri.map((ad) => {
      let yanUrunToplam = 0;
      if (ad.secilenYanUruns) {
        ad.secilenYanUruns.map((yan) => {
          yanUrunToplam += yan.fiyat;
        });
      }
      toplamT += ad.fiyat * ad.adet + yanUrunToplam;
    });
    setToplamTutar(toplamT);
    setOtomatikSiparisVer(true);
    if (bilgilerGeldi) {
      dispatch(setUrunList(adisyonBilgileri));
    }
    setBilgilerGeldi(true);
  }, [adisyonBilgileri, common.selectedDilItems]);
  useEffect(() => {
    if (
      routeParams.onay == 1 &&
      toplamTutar != null &&
      otomatikSiparisVer &&
      selectedTab == 1
    ) {
      setModalSepeteEkle(true);
    }
  }, [otomatikSiparisVer, toplamTutar]);
  function siparisVer() {
    if (!user.accessToken) {
      history.push("/login");
      return;
    }
    setProcessing(true);
    let siparis = {
      masaId: common.masaId,
      toplamTutar: toplamTutar,
    };
    let urunListesi = [];

    adisyonBilgileri.map((ad) => {
      let newUrun = {
        urunId: ad.urunId,
        urunAdi: ad.urunAdi,
        birimAdi: ad.birimAdi,
        adet: ad.adet,
        urunFiyatId: ad.urunFiyatId,
        fiyat: ad.fiyat,
        siparisUrunTarihi: formatDateTimeServer(new Date(ad.siparisTarihi)),
        urunNotu: ad.siparisNotu,
        urunNotList: ad.siparisNotList,
        menuIcindekiYanUrunlerString: ad.menuIcindekiYanUrunlerString,
        ucretsizUrunHakki: ad.ucretsizUrunHakki,
        ucretsizUrunFiyatIds: ad.ucretsizUrunFiyatIds,
        isUcretsizHak: ad.isUcretsizHak,
      };
      if (ad.secilenYanUruns) {
        let yanUruns = [];
        ad.secilenYanUruns.map((yan) => {
          let newYanUrun = {
            urunId: yan.urunId,
            urunAdi: yan.adi,
            fiyat: yan.fiyat,
          };
          yanUruns.push(newYanUrun);
        });
        newUrun.secilenYanUruns = yanUruns;
      }
      urunListesi.push(newUrun);
    });
    siparis.urunListesi = urunListesi;

    dispatch(setYeniSiparis(siparis)).then((rest) => {
      if (rest.result) {
        setProcessing(false);
        setAdisyonBilgileri([]);
        getSiparisler();
        setSelectedTab(2);
        setOtomatikSiparisVer(false);

        alert.show(common.selectedDilItems.siparisVerildiTxt, {
          type: "success",
          timeout: 3000,
        });
      } else {
        alert.show(common.selectedDilItems.islemSirasindaHataOlustuTxt, {
          type: "error",
          timeout: 4000,
        });
      }
    });
  }
  function siparisSil(index) {
    setSelectedSipIndex(index);
    setSilModalOpen(true);
  }
  function siparisSilConfirm() {
    debugger;
    let adisyonBilgileriCopy = [...adisyonBilgileri];

    // let silinenUrun = adisyonBilgileriCopy.filter(
    //   (ur, aindex) => aindex == selectedSipIndex
    // );
    let silinenUrun = adisyonBilgileriCopy[selectedSipIndex];

    let newadisyonBilgileriCopy = adisyonBilgileriCopy.filter(
      (ur, aindex) => aindex !== selectedSipIndex
    );
    let newadisyonBilgileriCopy2 = [];
    // const ucretsizHakkiVarMi = silinenUrun.find((obj) => {
    //   return obj.ucretsizUrunFiyatIds !== null;
    // });

    let silinenUrunSayisi = 0;
    let silinenIndexs = [];
    if (silinenUrun.ucretsizUrunHakki > 0) {
      silinenUrun.ucretsizUrunFiyatIds.map((ufId) => {
        newadisyonBilgileriCopy.map((newAd, index) => {
          if (newAd.urunFiyatId == ufId && newAd.isUcretsizHak) {
            if (silinenUrun.ucretsizUrunHakki >= silinenUrunSayisi) {
              silinenUrunSayisi = silinenUrunSayisi + newAd.adet;
              silinenIndexs.push(index);
            }
          }
        });
        // let ucretsizurunIndex = adisyonBilgileriCopy.findIndex((t) => {
        //   return t.urunFiyatId == ufId;
        // });
        // adisyonBilgileriCopy = adisyonBilgileriCopy.filter(
        //   (ur, uindex) => uindex !== ucretsizurunIndex
        // );
      });
      if (silinenIndexs.length > 0) {
        for (let index = 0; index < newadisyonBilgileriCopy.length; index++) {
          if (silinenIndexs.indexOf(index) < 0) {
            newadisyonBilgileriCopy2.push(newadisyonBilgileriCopy[index]);
          }
          // silinenIndexs.map((s) => {
          //   if (s != index) {
          //     newadisyonBilgileriCopy2.push(newadisyonBilgileriCopy[index]);
          //   }
          // });
        }
        // newadisyonBilgileriCopy.map((a, ai) => {
        //   silinenIndexs.map((s) => {
        //     if (s != ai) {
        //       newadisyonBilgileriCopy2.push(a);
        //     }
        //   });
        // });
      }
      setAdisyonBilgileri(newadisyonBilgileriCopy2);
      window.localStorage.setItem(
        "selectedUruns",
        JSON.stringify(newadisyonBilgileriCopy2)
      );
    } else {
      setAdisyonBilgileri(newadisyonBilgileriCopy);
      window.localStorage.setItem(
        "selectedUruns",
        JSON.stringify(newadisyonBilgileriCopy)
      );
    }
  }

  function getSiparisler() {
    dispatch(getSiparislerim()).then((data) => {
      setToplamBorc(data.data.toplamTutar);
      setSiparislerim(data.data.siparisListesi);
    });
  }

  function siparisIptal(Id) {
    setIptalSipId(Id);
    setSilModalOpen(true);
  }
  function siparisIptalConfirm() {
    dispatch(setSiparisIptal(iptalSipId)).then((data) => {
      if (data.result) {
        getSiparisler();
      } else {
        alert.show(data.message, { type: "error" });
      }
    });
  }
  function odemeYap() {
    history.push("/odeme");
  }
  function GetOdemeBtn() {
    return (
      <Button
        style={{
          fontSize: 12,
          fontWeight: "bold",
          marginBottom: 4,
          width: "100%",
          marginTop: 5,
          marginLeft: 2,
          marginBottom: 10,
          color: "#fff",
          backgroundColor: "rgb(249, 23, 47)",
          borderColor: "rgb(249, 23, 47)",
          borderRadius: "9px",
        }}
        onClick={() => {
          odemeYap();
        }}
      >
        {/* Ödeme Yap */}
        {common.selectedDilItems.odemeYapTxt} : {toplamBorc} TL
      </Button>
    );
  }
  if (adisyonBilgileri != null) {
    return (
      <>
        <Modal
          isOpen={modalSepeteEkle}
          toggle={toggleSepeteEkle}
          centered={true}
        >
          <ModalHeader>
            {/* Sipariş Bilgi */}
            {common.selectedDilItems.siparisBilgiModalHeaderTxt}
            <Button
              style={{
                borderWidth: 0,
                backgroundColor: "#fff",
                color: "#000",
                float: "right",
                marginTop: -5,
              }}
              onClick={() => {
                setModalSepeteEkle(false);
              }}
            >
              X
            </Button>
          </ModalHeader>
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{
                __html: common.selectedDilItems.siparisBilgiModalBodyTxt
                  .replace(
                    common.selectedDilItems.urunEkleBtn,
                    "<span style='color:blue;'> " +
                      common.selectedDilItems.urunEkleBtn +
                      "</span>"
                  )
                  .replace(
                    common.selectedDilItems.siparisOnaylaTxt,
                    "<span style='color:red;'> " +
                      common.selectedDilItems.siparisOnaylaTxt +
                      "</span>"
                  ),
              }}
            >
              {/* Siparişinizi onaylamak için "Siparişi Onayla" butonuna tıklayınız. */}
              {/* İlave ürün eklemek için aşağıdaki{" "}
              <span style={{ color: "blue" }}>"Ürün Ekle"</span> butonunu
              tıklayın, siparişinizi sonlandırmak ve göndermek için ise
              <span style={{ color: "red" }}> "Siparişi Onayla"</span> butonunu
              tıklayın. */}
              {/* <div style={{ width: "100%" }}>
                <div
                  style={{ textAlign: "center", marginTop: 20, float: "left" }}
                >
                  <Button
                    onClick={() => {
                      setModalSepeteEkle(false);
                      history.push("/");
                    }}
                  >
                    Başka Ürün Ekle
                  </Button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    float: "left",
                    marginLeft: 50,
                  }}
                >
                  <Button
                    style={{ backgroundColor: "rgb(249, 23, 47)" }}
                    onClick={() => {
                      setModalSepeteEkle(false);
                      siparisVer();
                    }}
                  >
                    Siparişi Onayla
                  </Button>
                </div>
              </div> */}
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={silModalOpen} toggle={toggle} centered={true}>
          <ModalHeader>
            {/* Doğrulama */}
            <div style={{ position: "absolute", right: 8, top: 1 }}>
              <i
                onClick={() => setSilModalOpen(false)}
                class="bi bi-x-circle"
              ></i>
            </div>
          </ModalHeader>
          <ModalBody>
            {/* {selectedTab == 1 && "Silmek "} {selectedTab == 2 && "İptal etmek "}
            isteğinizden emin misiniz? */}
            {common.selectedDilItems.eminMisinizTxt}
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                if (selectedTab == 1) {
                  siparisSilConfirm();
                } else {
                  siparisIptalConfirm();
                }
                toggle();
              }}
            >
              {/* Evet */}
              {common.selectedDilItems.evetTxt}
            </Button>
            <Button onClick={() => toggle()}>
              {" "}
              {common.selectedDilItems.hayirTxt}
            </Button>
          </ModalFooter>
        </Modal>

        <div
          className="row"
          style={{ marginTop: 5, padding: 9, paddingBottom: 0 }}
        >
          <div className="col-md-12">
            <div className="row">
              <h5>{common.selectedDilItems.siparisBaslikTxt}</h5>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <Button
                style={{
                  marginRight: 3,
                  fontSize: width > 500 ? 10 : width > 400 ? 8 : 7,
                  backgroundColor:
                    selectedTab == 1 ? "rgb(246, 25, 42)" : "#242424",
                }}
                onClick={() => setSelectedTab(1)}
              >
                {/* Bekleyen Sip. */}
                {common.selectedDilItems.bekleyenSiparislerTxt}
              </Button>
              <Button
                style={{
                  marginRight: 3,
                  fontSize: width > 500 ? 10 : width > 400 ? 8 : 7,
                  backgroundColor:
                    selectedTab == 2 ? "rgb(246, 25, 42)" : "#242424",
                }}
                onClick={() => setSelectedTab(2)}
              >
                {/* Siparişlerim */}
                {common.selectedDilItems.siparislerimTxt}
              </Button>
              <Button
                style={{
                  fontSize: width > 500 ? 10 : width > 400 ? 8 : 7,
                  backgroundColor:
                    selectedTab == 3 ? "rgb(246, 25, 42)" : "#242424",
                }}
                onClick={() => setSelectedTab(3)}
              >
                {/* Geçmiş Sip. */}
                {common.selectedDilItems.gecmisSiparislerimTxt}
              </Button>
            </div>
          </div>
        </div>
        {selectedTab == 1 && (
          <>
            <div
              className="row"
              style={{
                marginTop: 0,
                padding: 15,
                marginBottom: 200,
                // marginBottom: 154,
              }}
            >
              {common.genelAyarlar["masadanSiparisSanalPosKullaniMi"] &&
                toplamBorc > 0 && <GetOdemeBtn />}
              <div
                className="col-md-12"
                style={{ backgroundColor: "#fff", borderRadius: 12 }}
              >
                {adisyonBilgileri.map((ur, index) => {
                  return (
                    <div
                      // className="siparisListesiDiv"
                      style={{
                        width: "100%",
                        paddingBottom: 5,
                        marginTop: 20,
                        fontWeight: "bold",
                        fontSize: 19,
                        marginLeft: 5,
                        borderBottom: "1px solid #dddddd8f",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "inline-block",
                        }}
                      >
                        <i
                          class="bi bi-record-circle"
                          style={{
                            float: "left",
                            color: "#3bf53b",
                            marginTop: 12,
                          }}
                        ></i>

                        <div
                          style={{
                            textAlign: "left",
                            backgroundColor: "transparent",
                            borderWidth: 0,
                            float: "left",
                            color: "#000",
                            marginLeft: 10,
                            maxWidth: width > 400 ? "50%" : "70%",
                          }}
                          color="secondary"
                        >
                          <div
                            style={{
                              fontSize: 11,
                              color: "rgb(174 170 170 / 71%)",
                              fontWeight: "500",
                            }}
                          >
                            {formatDateTime(
                              new Date(ur.siparisTarihi),
                              common.selectedDilItems
                            )}{" "}
                            {common.masaNoAdi}
                          </div>

                          <div style={{ fontSize: 14 }}>
                            {ur.urunAdi}
                            <span
                              style={{
                                color: "rgb(174 170 170 / 71%)",
                                fontWeight: "500",
                                fontSize: 13,
                              }}
                            >
                              {" "}
                              ({ur.adet} X {ur.adetFiyat} {ur.birimAdi})
                            </span>
                          </div>

                          <div
                            style={{
                              fontSize: 11,
                              color: "rgb(174 170 170 / 71%)",
                            }}
                          >
                            {ur.siparisNotu}
                            {ur.siparisNotList.map((nns) => {
                              return <span> {nns.digerDilAdi}</span>;
                            })}
                          </div>
                          {ur.fiyat > 0 && (
                            <div style={{ width: "100%", height: 40 }}>
                              <div
                                // className="urunDetayNumber"
                                style={{
                                  // float: "left",
                                  display: "initial",
                                  marginRight: 10,
                                  // color: "#f6192a",
                                  borderRadius: 6,
                                  marginTop: 5,
                                }}
                              >
                                <select
                                  className="urunDetaySelect"
                                  onChange={(event) => {
                                    let adisyonBilgileriCopy = [
                                      ...adisyonBilgileri,
                                    ];
                                    let yeniUr = _.clone(ur);
                                    yeniUr.adet = event.target.value;
                                    adisyonBilgileriCopy[index] = yeniUr;
                                    setAdisyonBilgileri(adisyonBilgileriCopy);
                                    window.localStorage.setItem(
                                      "selectedUruns",
                                      JSON.stringify(adisyonBilgileriCopy)
                                    );
                                    // setUrunCount(event.target.value);
                                  }}
                                  style={{
                                    float: "left",
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    fontSize: 13,
                                    height: 28,
                                    marginTop: 5,
                                  }}
                                >
                                  <option
                                    value={"1"}
                                    selected={ur.adet == 1 ? true : false}
                                  >
                                    1
                                  </option>
                                  <option
                                    value={"2"}
                                    selected={ur.adet == 2 ? true : false}
                                  >
                                    2
                                  </option>
                                  <option
                                    value={"3"}
                                    selected={ur.adet == 3 ? true : false}
                                  >
                                    3
                                  </option>
                                  <option
                                    value={"4"}
                                    selected={ur.adet == 4 ? true : false}
                                  >
                                    4
                                  </option>
                                  <option
                                    value={"5"}
                                    selected={ur.adet == 5 ? true : false}
                                  >
                                    5
                                  </option>
                                  <option
                                    value={"6"}
                                    selected={ur.adet == 6 ? true : false}
                                  >
                                    6
                                  </option>
                                  <option
                                    value={"7"}
                                    selected={ur.adet == 7 ? true : false}
                                  >
                                    7
                                  </option>
                                  <option
                                    value={"8"}
                                    selected={ur.adet == 8 ? true : false}
                                  >
                                    8
                                  </option>
                                  <option
                                    value={"9"}
                                    selected={ur.adet == 9 ? true : false}
                                  >
                                    9
                                  </option>
                                </select>
                                {/* <button
                                className="artiEksiBtn"
                                style={{
                                  backgroundColor: "transparent",
                                  borderWidth: 0,
                                  marginRight: 5,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (ur.adet > 1) {
                                    let adisyonBilgileriCopy = [
                                      ...adisyonBilgileri,
                                    ];
                                    let yeniUr = _.clone(ur);
                                    yeniUr.adet = yeniUr.adet - 1;
                                    adisyonBilgileriCopy[index] = yeniUr;
                                    setAdisyonBilgileri(adisyonBilgileriCopy);
                                  }
                                }}
                              >
                                -
                              </button>
                              {ur.adet}
                              <button
                                className="artiEksiBtn"
                                style={{
                                  backgroundColor: "transparent",
                                  borderWidth: 0,
                                  marginLeft: 5,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let adisyonBilgileriCopy = [
                                    ...adisyonBilgileri,
                                  ];
                                  let yeniUr = _.clone(ur);
                                  yeniUr.adet = yeniUr.adet + 1;
                                  adisyonBilgileriCopy[index] = yeniUr;
                                  setAdisyonBilgileri(adisyonBilgileriCopy);
                                }}
                              >
                                +
                              </button> */}
                              </div>
                            </div>
                          )}
                          <div style={{ fontSize: 9, fontWeight: 300 }}>
                            {ur.menuIcindekiYanUrunlerString}
                          </div>
                          {ur.secilenYanUruns &&
                            ur.secilenYanUruns.map((yanUr) => {
                              return (
                                <div>
                                  <div>
                                    <i
                                      class="bi bi-x"
                                      style={{ color: "#d02736" }}
                                    ></i>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        color: "rgb(174 170 170 / 71%)",
                                        fontWeight: "500",
                                        fontSize: 13,
                                        position: "relative",
                                        top: -3,
                                      }}
                                    >
                                      {yanUr.adi} (+{yanUr.fiyat} TL)
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>

                        <div
                          style={{
                            float: "right",
                            marginTop: 0,
                            minWidth: 60,
                            textAlign: "right",
                          }}
                        >
                          <div>{ur.adet * ur.fiyat} TL</div>
                          <div>
                            <Button
                              style={{
                                backgroundColor: "gray",
                                cursor: "pointer",
                                fontSize: 10,
                                padding: 2,
                                width: 50,
                              }}
                              onClick={() => {
                                siparisSil(index);
                              }}
                            >
                              {/* Sil */}
                              {common.selectedDilItems.silBtnTxt}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="bottomFooterSiparisDiv">
              <div
                className="col-md-12"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 12,
                  marginTop: 10,
                  paddingTop: width > 600 ? 20 : 10,
                  display: "inline-block",
                  paddingBottom: width > 600 ? 10 : 5,
                  paddingTop: width > 600 ? 20 : 10,
                  boxShadow: "rgb(0 0 0 / 6%) 0px 1px 1px 1px",
                }}
              >
                <div
                  style={{
                    float: width > 600 ? "left" : "center",
                    textAlign: "center",
                  }}
                >
                  <Button
                    style={{ fontSize: 14, marginRight: 10 }}
                    onClick={() => {
                      setModalSepeteEkle(false);
                      history.push("/");
                    }}
                  >
                    {/* ÜRÜN EKLE */}
                    {common.selectedDilItems.urunEkleBtn}
                  </Button>
                  {toplamTutar >= 0 && (
                    <Button
                      color="danger"
                      style={{ backgroundColor: "#f6192a", fontSize: 14 }}
                      onClick={() => siparisVer()}
                      disabled={processing || adisyonBilgileri.length == 0}
                    >
                      {/* SİPARİŞİ ONAYLA */}
                      {common.selectedDilItems.siparisOnaylaTxt}
                    </Button>
                  )}
                </div>
                <div style={{ float: "right" }}>
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginRight: 5,
                    }}
                  >
                    {/* Toplam */}
                    {common.selectedDilItems.toplamTxt}
                  </span>
                  <span
                    style={{
                      fontSize: 20,
                      color: "#f6192a",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {toplamTutar} TL
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {(selectedTab == 2 || selectedTab == 3) && (
          <div
            className="row"
            style={{ marginTop: 0, padding: 15, marginBottom: 140 }}
          >
            {common.genelAyarlar["masadanSiparisSanalPosKullaniMi"] &&
              toplamBorc > 0 && <GetOdemeBtn />}
            {siparislerim.map((sip) => {
              if (
                (selectedTab == 2 && sip.siparisDurumu == 0) ||
                (selectedTab == 3 && sip.siparisDurumu == 1)
              ) {
                return (
                  <>
                    <div
                      className="col-md-12"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 12,
                        marginBottom: 15,
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            fontSize: 11,
                            float: "left",
                            color: "rgb(174 170 170 / 71%)",
                            fontWeight: "500",
                          }}
                        >
                          {formatDateTime(
                            new Date(sip.siparisTarihi),
                            common.selectedDilItems
                          )}{" "}
                          - {sip.masaAdi}
                        </div>
                        <div
                          style={{
                            float: "right",
                            marginTop: 0,
                            minWidth: 90,
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            {" "}
                            {common.selectedDilItems.toplamTxt} :{" "}
                            {sip.toplamTutar} TL{" "}
                          </div>
                        </div>
                      </div>
                      {sip.urunListesi &&
                        sip.urunListesi.map((ur) => {
                          return (
                            <div
                              style={{
                                width: "100%",
                                paddingBottom: 5,
                                marginTop: 20,
                                fontWeight: "bold",
                                fontSize: 19,
                                marginLeft: 5,
                                borderBottom: "1px solid #dddddd8f",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                <i
                                  class="bi bi-record-circle"
                                  style={{
                                    float: "left",
                                    color: "#3bf53b",
                                    marginTop: 12,
                                  }}
                                ></i>

                                <div
                                  style={{
                                    textAlign: "left",
                                    backgroundColor: "transparent",
                                    borderWidth: 0,
                                    float: "left",
                                    color: "#000",
                                    marginLeft: 10,
                                    maxWidth: "50%",
                                  }}
                                  color="secondary"
                                >
                                  <div
                                    style={{
                                      fontSize: 11,
                                      color: "rgb(174 170 170 / 71%)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {formatDateTime(
                                      new Date(ur.siparisUrunTarihi),
                                      common.selectedDilItems
                                    )}{" "}
                                  </div>
                                  <div style={{ fontSize: 14 }}>
                                    {ur.urunAdi}
                                    <span
                                      style={{
                                        color: "rgb(174 170 170 / 71%)",
                                        fontWeight: "500",
                                        fontSize: 13,
                                      }}
                                    >
                                      {" "}
                                      ({ur.adet} X {ur.adetFiyat} {ur.birimAdi})
                                    </span>
                                  </div>
                                  {/* <div
                                  style={{
                                    fontSize: 11,
                                    color: "rgb(174 170 170 / 71%)",
                                    fontWeight: "500",
                                  }}
                                >
                                  Durum: {ur.siparisUrunDurumuString}
                                </div> */}
                                  <div
                                    style={{
                                      fontSize: 11,
                                      color: "rgb(174 170 170 / 71%)",
                                    }}
                                  >
                                    {ur.siparisNotu}
                                  </div>
                                  <div style={{ fontSize: 9, fontWeight: 300 }}>
                                    {ur.menuIcindekiYanUrunlerString}
                                  </div>
                                  {ur.secilenYanUruns &&
                                    ur.secilenYanUruns.map((yanUr) => {
                                      return (
                                        <div>
                                          <div>
                                            <i
                                              class="bi bi-x"
                                              style={{ color: "#d02736" }}
                                            ></i>
                                            <span
                                              style={{
                                                marginLeft: 5,
                                                color: "rgb(174 170 170 / 71%)",
                                                fontWeight: "500",
                                                fontSize: 13,
                                                position: "relative",
                                                top: -3,
                                              }}
                                            >
                                              {yanUr.urunAdi} (+{yanUr.fiyat}{" "}
                                              TL)
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>

                                <div
                                  style={{
                                    float: "right",
                                    marginTop: 0,
                                    minWidth: 90,
                                    textAlign: "right",
                                  }}
                                >
                                  <div>{ur.adet * ur.fiyat} TL</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {sip.siparisDurumu == 0 && (
                        <Button
                          style={{
                            fontSize: 11,
                            marginBottom: 4,
                            padding: "3px 21px",
                            marginTop: 5,
                          }}
                          onClick={() => {
                            siparisIptal(sip.id);
                          }}
                        >
                          {/* İptal */}
                          {common.selectedDilItems.iptalBtnTxt}
                        </Button>
                      )}
                    </div>
                  </>
                );
              }
            })}
            {siparislerim.length == 0 && (
              <div
                className="col-md-12"
                style={{ textAlign: "center", marginTop: 20 }}
              >
                <h5>{common.selectedDilItems.siparisBulunmamaktaTxt}</h5>
              </div>
            )}
          </div>
        )}
      </>
    );
  } else {
    return (
      <div>
        <h5>{common.selectedDilItems.siparisBulunmamaktaTxt}</h5>
      </div>
    );
  }
}
export default Siparisler;
