import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import {
  musteriTelDogrulama,
  musteriTelGiris,
} from "../store/reducers/userSlice";
import { useAlert } from "react-alert";
function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const alert = useAlert();
  useEffect(() => {
    if (user.accessToken) {
      history.push("/");
    }
  }, []);

  const [phoneNumber, setPhoneNumber] = useState();
  const [adSoyad, setAdSoyad] = useState("");
  const [aydinlatmaMetni, setAydinlatmaMetni] = useState(false);

  const [kod, setKod] = useState("");

  const [telefonSend, setTelefonSend] = useState(false);
  const [processing, setProcessing] = useState(false);

  // const [timer, setTimer] = useState(180000);
  const [timerTxt, setTimerTxt] = useState("");
  const timerStartRef = useRef(180000);
  const [startTimer, setStartTimer] = useState(false);

  const [telefonOnEk, setTelefonOnEk] = useState("+90");
  const [telefonNoPlaceHolder, setTelefonNoPlaceHolder] =
    useState("(555) 555 55 55");
  useEffect(() => {
    let intervalId = setInterval(() => {
      if (startTimer) {
        if (timerStartRef.current == 0) {
          window.location.reload();
        } else {
          let newTimer = timerStartRef.current - 1000;
          var dakkaKalan = newTimer % 60000;
          var dakika = (newTimer - dakkaKalan) / 60000;
          var saniyeKalan = dakkaKalan % 1000;
          var saniye = (dakkaKalan - saniyeKalan) / 1000;
          var txt = "0" + dakika + ":";
          if (saniye > 9) {
            txt = txt + saniye;
          } else {
            txt = txt + "0" + saniye;
          }
          setTimerTxt(txt);
          timerStartRef.current = newTimer;
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
      setTimerTxt("");
    };
  }, [startTimer]);
  const inputChange = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, "");
    let num;
    if (telefonOnEk == "+90") {
      num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(
        6,
        8
      )} ${val.substring(8, 10)}`;
    } else {
      num = val;
    }
    num = num.trim();
    setPhoneNumber(num);
  };
  const kaydet = () => {
    if (!telefonSend) {
      if (!aydinlatmaMetni) {
        alert.show(common.selectedDilItems.aydinlatmaMetniniOnaylaTxt, {
          type: "error",
          timeout: 3000,
        });
        return;
      }
      if (phoneNumber) {
        if (common.genelAyarlar["masadanSiparisGiristeSmsGonderilsinMi"]) {
          setProcessing(true);
          let newData = {
            phoneNumber: telefonOnEk + phoneNumber.trim().replace(/\s/g, ""),
            adSoyad: adSoyad,
          };
          dispatch(musteriTelDogrulama(newData)).then((data) => {
            if (data && data.result) {
              setTelefonSend(true);
              setProcessing(false);
              setStartTimer(true);
            } else {
              alert.show("Bir hata oluştu", {
                type: "error",
                timeout: 3000,
              });
              setTelefonSend(false);
              setProcessing(false);
            }
          });
        } else {
          setProcessing(true);
          let newData = {
            phoneNumber: telefonOnEk + phoneNumber.trim().replace(/\s/g, ""),
            kod: "",
            adSoyad: adSoyad,
          };
          dispatch(musteriTelGiris(newData)).then((data) => {
            if (data.result) {
              debugger;
              history.push("/siparisler/0");
            } else {
              alert.show(data.message, {
                type: "error",
                timeout: 3000,
              });
              setProcessing(false);
            }
          });
        }
      } else {
        alert.show("Lütfen telefon numaranızı eksiksiz giriniz.", {
          type: "error",
          timeout: 3000,
        });
      }
    } else {
      if (kod && kod.length == 6) {
        setProcessing(true);
        let newData = {
          phoneNumber: telefonOnEk + phoneNumber.trim().replace(/\s/g, ""),
          kod: kod,
        };
        dispatch(musteriTelGiris(newData)).then((data) => {
          if (data.result) {
            debugger;
            history.push("/siparisler/0");
          } else {
            alert.show(data.message, {
              type: "error",
              timeout: 3000,
            });
            setProcessing(false);
          }
        });
      } else {
        alert.show(common.selectedDilItems.lutfenGelenKoduGirinizTxt, {
          type: "error",
          timeout: 3000,
        });
      }
    }
  };
  return (
    <div className="row" style={{ marginBottom: 120 }}>
      <div
        className="col-md-12 "
        style={{ textAlign: "center", marginTop: 30 }}
      >
        {/* <img
          // src={"./assets/logogenel.png"}
          src={"./assets/splashscreen.png"}
          style={{
            width: 300,
            height: 100,
            backgroundColor: "red",
            objectFit: "contain",
            paddingRight: 22,
            paddingLeft: 22,
          }}
        /> */}

        <img
          src={"./assets/NOVCOD_LOGO_VECTOR.png"}
          style={{
            width: "100%",
            maxWidth: window.width,
            backgroundColor: "#000000bd",
            height: 200,
            objectFit: "contain",
            padding: 10,
            borderRadius: 10,
          }}
        />

        <h5 style={{ fontSize: 30, marginTop: 20 }}>{common.masaAdi}</h5>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-8 col-12 offset-lg-4 offset-md-3 offset-sm-2">
        <div className="form-group">
          <label style={{ color: "#000", fontWeight: "600" }}>
            {/* Telefon Numarası */}
            {common.selectedDilItems.telefonNumarasiTxt}
          </label>
          <div style={{ flexDirection: "row" }}>
            <select
              disabled={telefonSend}
              className="form-control"
              style={{ width: "25%", float: "left" }}
              onChange={(event) => {
                setTelefonOnEk(event.target.value);
                if (event.target.value == "+90") {
                  setTelefonNoPlaceHolder("(555) 555 55 55");
                } else {
                  setTelefonNoPlaceHolder("");
                }
              }}
            >
              <option value={"+90"}>+90</option>
              <option value={"+31"}>+31</option>
              <option value={"+33"}>+33</option>
              <option value={"+34"}>+34</option>
            </select>
            <input
              style={{ width: "75%", float: "left" }}
              className="form-control"
              placeholder={telefonNoPlaceHolder}
              onChange={inputChange}
              value={phoneNumber}
              disabled={telefonSend}
            />
            <p style={{ fontSize: 9, color: "#333", marginTop: 2 }}>
              * {common.selectedDilItems.siparisVermekIcinBuAlanZorunluTxt}
            </p>
          </div>
        </div>
        <div className="form-group">
          <label style={{ color: "#000", fontWeight: "600" }}>
            {common.selectedDilItems.adSoyadTxt}
          </label>
          <input
            className="form-control"
            placeholder={common.selectedDilItems.adSoyadTxt}
            onChange={(e) => setAdSoyad(e.target.value)}
            value={adSoyad}
            disabled={telefonSend}
          />
          <p style={{ fontSize: 9, color: "#333", marginTop: 2 }}>
            * {common.selectedDilItems.zorunluAlanDegilTxt}
          </p>
        </div>
        <div className="form-group">
          {/* <label style={{ color: "#000", fontWeight: "600" }}>Ad Soyad</label> */}
          <input
            type="checkbox"
            // className="form-control"
            // placeholder="Ad Soyad"
            onChange={(e) => setAydinlatmaMetni(e.target.checked)}
            value={aydinlatmaMetni}
            disabled={telefonSend}
            style={{ width: 20, height: 20 }}
          />
          <span
            style={{
              fontSize: 17,
              color: "#333",
              position: "relative",
              top: -5,
              marginLeft: 10,
            }}
          >
            <a href="AYDINLATMA_METNI.pdf" target={"_blank"}>
              {common.selectedDilItems.aydinlatmaMetniTxt}
            </a>
          </span>
        </div>

        {telefonSend && (
          <div className="form-group">
            <label style={{ color: "#000", fontWeight: "600", width: "100%" }}>
              {common.selectedDilItems.kodTxt}
              {timerTxt != "" && (
                <span
                  style={{ marginLeft: 15, color: "#d02736", float: "right" }}
                >
                  {timerTxt}
                </span>
              )}
            </label>
            <input
              className="form-control"
              placeholder={common.selectedDilItems.altiHaneliKodTxt}
              onChange={(e) => setKod(e.target.value.trim())}
              value={kod}
              disabled={!telefonSend}
            />
            <p style={{ fontSize: 9, color: "#333", marginTop: 2 }}>
              * {common.selectedDilItems.telefonNoEksiksizGirTxt}
            </p>
          </div>
        )}
        <div className="form-group">
          <Button
            color={!processing ? "danger" : "secondary"}
            style={{ width: "100%", backgroundColor: "#f6192a" }}
            onClick={() => {
              kaydet();
            }}
            disabled={processing}
          >
            {/* Giriş */}
            {common.selectedDilItems.girisBtnTxt}
          </Button>
        </div>
      </div>
      {/* <h1>Login</h1> */}
    </div>
  );
}
export default Login;
