import React, { useState, useEffect } from "react";
import { getAnaSayfaItems } from "../services/commonService";
import { addNewUrun } from "../store/reducers/urunSlice";
import { setUrunDegerlendir } from "../store/reducers/userSlice";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import { getUrunDetay } from "../services/commonService";
import useWindowDimensions from "../components/windowDimensions";
import {
  Spinner,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import _ from "lodash";
import { useAlert } from "react-alert";
// import Carousel from "reactstrap/lib/Carousel";
// import CarouselControl from "reactstrap/lib/CarouselControl";
// import CarouselItem from "reactstrap/lib/CarouselItem";
// import CarouselIndicators from "reactstrap/lib/CarouselIndicators";
// import CarouselCaption from "reactstrap/lib/CarouselCaption";

import SwiperUrunDetayFoto from "../components/SwiperUrunDetayFoto";
const UrunDetay = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const { urunId } = useParams();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);
  const urun = useSelector((state) => state.urun);

  const [urunDetay, setUrunDetay] = useState();
  //1 Yükleniyor, 2 Yüklendi, 3 Ürün Bulunamadı
  const [durum, setDurum] = useState(1);
  const [currentHeight, setCurrentHeight] = useState(0);
  const [selectedUrunFiyat, setSelectedUrunFiyat] = useState(0); // ürün fiyatın Id'si
  const [selectedUrunFiyatFull, setSelectedUrunFiyatFull] = useState(); // ürün fiyatın Id'si

  const [urunCount, setUrunCount] = useState(1);
  const [currentUrunFiyat, setCurrentUrunFiyat] = useState(0); // sagda yazan ürünün fiyatı
  const [eklenenYanUrunler, setEklenenYanUrunler] = useState([]);
  const [siparisNotu, setSiparisNotu] = useState("");
  const [siparisNotList, setSiparisNotList] = useState([]);

  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const [alerjikModalText, setAlerjikModalText] = useState("");
  const [alerjikModalHeaderText, setAlerjikModalHeaderText] = useState("");

  const toggleDegerlendir = () => setModalDegerlendir(!modalDegerlendir);
  const [modalDegerlendir, setModalDegerlendir] = useState(false);

  useEffect(() => {
    dispatch(getUrunDetay(urunId)).then((data) => {
      if (data.result) {
        setUrunDetay(data.data);
        console.log(data.data);
        setCurrentUrunFiyat(data.data.urunFiyatlari[0].fiyat);
        setSelectedUrunFiyat(data.data.urunFiyatlari[0].id);
        setSelectedUrunFiyatFull(data.data.urunFiyatlari[0]);
        setDurum(2);
      } else {
        setDurum(3);
      }
    });
  }, [dispatch, common.selectedDilItems]);
  useEffect(() => {
    updateUrunFiyat();
  }, [selectedUrunFiyat]);
  useEffect(() => {
    updateUrunFiyat();
  }, [urunCount]);

  useEffect(() => {
    updateUrunFiyat();
  }, [eklenenYanUrunler]);
  function updateUrunFiyat() {
    if (urunDetay != null) {
      urunDetay.urunFiyatlari.map((fiy) => {
        if (fiy.id == selectedUrunFiyat) {
          let yanUrunFiyat = 0;
          eklenenYanUrunler.map((yan) => {
            yanUrunFiyat += yan.fiyat;
          });
          let toplamT = fiy.fiyat * urunCount + yanUrunFiyat;
          setCurrentUrunFiyat(toplamT);
          setSelectedUrunFiyatFull(fiy);
          return;
        }
      });
    }
  }

  useEffect(() => {
    let newHeight = 0;
    if (width > 1200) {
      newHeight = width / 6;
    } else if (width > 1000) {
      newHeight = width / 7;
    } else if (width > 800) {
      newHeight = width / 7;
    } else if (width > 600) {
      newHeight = width / 7;
    } else {
      newHeight = width / 3;
    }
    setCurrentHeight(newHeight);
  }, []);

  const [adisyonBilgileri, setAdisyonBilgileri] = useState(urun.urunList);

  function getUrunUcretsizHakDurumu(secilenUrun, getKalan) {
    let ucretsizMi = false;
    let kalanUcretsizHak = 0;
    let siparisUrunList = [];
    if (
      urun.siparisList &&
      urun.siparisList.siparisListesi &&
      urun.siparisList.siparisListesi.length > 0
    ) {
      let newsiparisList = urun.siparisList.siparisListesi.filter(
        (sip, sindex) =>
          new Date(sip.siparisTarihi).getTime() + 7200000 >=
          new Date().getTime()
      ); // iki saat geçerli
      if (newsiparisList.length > 0) {
        newsiparisList.map((sip) => {
          sip.urunListesi.map((ss) => siparisUrunList.push(ss));
        });
      }
    }
    let adisyonUrunListesiCopy = [...adisyonBilgileri, ...siparisUrunList];

    let urunUcretsizFiyatHak = {};
    adisyonUrunListesiCopy.map((tAd) => {
      if (tAd.ucretsizUrunHakki > 0) {
        tAd.ucretsizUrunFiyatIds.map((uuf) => {
          if (urunUcretsizFiyatHak[uuf]) {
            urunUcretsizFiyatHak[uuf].ucretsizHakki +=
              tAd.adet * tAd.ucretsizUrunHakki;
          } else {
            urunUcretsizFiyatHak[uuf] = {
              ucretsizHakki: tAd.adet * tAd.ucretsizUrunHakki,
              kullanilan: 0,
              anaUrunFiyatId: tAd.urunFiyatId,
            };
          }
        });
      }
    });

    adisyonUrunListesiCopy.map((tAd) => {
      if (tAd.isUcretsizHak && urunUcretsizFiyatHak[tAd.urunFiyatId]) {
        urunUcretsizFiyatHak[tAd.urunFiyatId].kullanilan += tAd.adet;
      }
    });

    if (secilenUrun.ucretsizUrunHakki > 0) {
      secilenUrun.ucretsizUrunFiyatIds.map((fiy) => {
        if (urunUcretsizFiyatHak[fiy]) {
          urunUcretsizFiyatHak[fiy].ucretsizHakki +=
            urunCount * secilenUrun.ucretsizUrunHakki;
        } else {
          urunUcretsizFiyatHak[fiy] = {
            ucretsizHakki: urunCount * secilenUrun.ucretsizUrunHakki,
            kullanilan: 0,
            anaUrunFiyatId: secilenUrun.urunFiyatId,
          };
        }
      });
    } else {
      if (urunUcretsizFiyatHak[secilenUrun.id]) {
        if (
          urunUcretsizFiyatHak[secilenUrun.id].ucretsizHakki >
          urunUcretsizFiyatHak[secilenUrun.id].kullanilan
        ) {
          let toplamKullanilan = 0;
          Object.keys(urunUcretsizFiyatHak).map(function (key, index) {
            let currentHak = urunUcretsizFiyatHak[key];
            if (
              currentHak.anaUrunFiyatId ==
              urunUcretsizFiyatHak[secilenUrun.id].anaUrunFiyatId
            ) {
              toplamKullanilan += currentHak.kullanilan;
            }
          });
          if (
            urunUcretsizFiyatHak[secilenUrun.id].ucretsizHakki >
            toplamKullanilan
          ) {
            kalanUcretsizHak =
              urunUcretsizFiyatHak[secilenUrun.id].ucretsizHakki -
              toplamKullanilan;

            urunUcretsizFiyatHak[secilenUrun.id].kullanilan += urunCount;
            ucretsizMi = true;
          }
        }
      }
    }
    if (getKalan) {
      return kalanUcretsizHak;
    }
    return ucretsizMi;
  }

  const siparisVer = async () => {
    debugger;
    if (
      urunDetay.urunOzellikSecmekZorunlu &&
      (!siparisNotList || siparisNotList.length == 0)
    ) {
      alert.show(common.selectedDilItems.urunOzelligiSecinizTxt, {
        type: "error",
      });
      return;
    }
    if (
      urunDetay.birTaneUrunOzellikSecebilir &&
      siparisNotList &&
      siparisNotList.length > 1
    ) {
      alert.show(common.selectedDilItems.sadeceBirOzellikSecTxt, {
        type: "error",
      });
      return;
    }
    console.log(urunDetay);
    console.log(eklenenYanUrunler);
    let urunFiyat = null;
    urunDetay.urunFiyatlari.map((fiy) => {
      if (fiy.id == selectedUrunFiyat) {
        urunFiyat = fiy;
      }
    });
    debugger;
    let ucretsizMi = getUrunUcretsizHakDurumu(urunFiyat, false);
    let kalanUcretsizHak = getUrunUcretsizHakDurumu(urunFiyat, true);
    let urunBol = false;
    if (ucretsizMi && urunCount > kalanUcretsizHak) {
      urunBol = true;
    }

    let newData = {
      urunId: urunId,
      adet: urunCount,
      adetFiyat: selectedUrunFiyatFull.adet,
      urunFiyatId: selectedUrunFiyat,
      siparisNotu: siparisNotu,
      siparisNotList: siparisNotList,
      toplamTutar: currentUrunFiyat,
      siparisTipi: common.siparisTipi,
      masaId: common.masaId,

      urunAdi: urunDetay.adi,
      foto: urunDetay.foto,
      hazirlanmaSuresi: urunDetay.hazirlanmaSuresi,
      alerjikEtkenler: urunDetay.alerjikEtkenler,

      fiyat: ucretsizMi ? 0 : urunFiyat.fiyat,
      //fiyat: urunFiyat.fiyat,
      birimAdi: urunFiyat.birimAdi,
      secilenYanUruns: eklenenYanUrunler,
      siparisTarihi: new Date(),
      menuIcindekiYanUrunlerString: urunFiyat.menuIcindekiYanUrunlerString,
      ucretsizUrunHakki: urunFiyat.ucretsizUrunHakki,
      ucretsizUrunFiyatIds: urunFiyat.ucretsizUrunFiyatIds,
      isUcretsizHak: ucretsizMi,
    };
    if (urunBol) {
      newData.adet = kalanUcretsizHak;
    }
    let yanIds = [];
    eklenenYanUrunler.map((yan) => {
      yanIds.push(yan.id);
    });
    newData.yanUrunIds = yanIds;
    dispatch(addNewUrun(newData));

    if (urunBol) {
      let nnewData = _.clone(newData);
      nnewData.adet = urunCount - kalanUcretsizHak;
      nnewData.fiyat = urunFiyat.fiyat;
      nnewData.isUcretsizHak = false;
      nnewData.ucretsizUrunHakki = 0;
      nnewData.ucretsizUrunFiyatIds = null;
      dispatch(addNewUrun(nnewData));
    }

    history.push("/siparisler/1");

    // alert.show(
    //   "Ürün sepete eklendi,onaylamak için sipariş ver butonuna tıklayınız.",
    //   { type: "success", timeout: 4000 }
    // );
  };
  function toogleSet(ur) {
    if (ur.alerjikEtkenler) {
      setAlerjikModalText(
        ur.alerjikEtkenler.map((y) => {
          return <div>{y}</div>;
        })
      );
      setAlerjikModalHeaderText(
        ur.adi + " " + common.selectedDilItems.alerjikEtkenlerTxt
      );
      setModal(!modal);
    } else {
      setAlerjikModalHeaderText(
        ur.adi + " " + common.selectedDilItems.alerjikEtkenlerTxt
      );
      setAlerjikModalText(
        common.selectedDilItems.alerjikEtkenlerBulunmamaktadirTxt
      );
      setModal(!modal);
    }
  }
  function artiButtonClick() {
    setUrunCount(urunCount + 1);
  }
  function eksiButtonClick() {
    if (urunCount > 1) {
      setUrunCount(urunCount - 1);
    }
  }
  const [yildizDurum, setYildizDurum] = useState(0);
  function urundegerlendir() {
    if (!user.accessToken) {
      history.push("/login");
    } else {
      setModalDegerlendir(false);
      dispatch(setUrunDegerlendir(urunDetay.id, yildizDurum)).then((data) => {
        if (data.result) {
          //update sayılar;
          setUrunDetay({
            ...urunDetay,
            puan: data.data.ortalamaPuan,
            degerlendirmeSayisi: data.data.degerlendirenSayisi,
          });
        } else {
          alert.show(data.message, { type: "error" });
          // alert.show(<div style={{ color: "blue" }}>Some Message</div>);
        }
        setYildizDurum(0);
      });
    }
  }
  if (durum == 3) {
    return (
      <div className="col-md-12">
        <h5 style={{ marginTop: 20, textAlign: "center" }}>
          Ürün Bilgilerine Ulaşılamadı.
        </h5>
      </div>
    );
  } else if (durum == 1) {
    return (
      <div className="col-md-12">
        <div className="splashLoader" style={{ position: "absolute", top: 60 }}>
          <Spinner
            animation="border"
            variant="light"
            style={{ color: "rgb(246 25 42)" }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader>
            {alerjikModalHeaderText}
            <div style={{ position: "absolute", right: 8, top: 1 }}>
              <i onClick={() => setModal(false)} class="bi bi-x-circle"></i>
            </div>
          </ModalHeader>
          <ModalBody>{alerjikModalText}</ModalBody>
        </Modal>

        <Modal
          isOpen={modalDegerlendir}
          toggle={toggleDegerlendir}
          centered={true}
        >
          <ModalHeader>
            {/* Değerlendirme */}
            {common.selectedDilItems.degerlendirmeTxt}
            <div style={{ position: "absolute", right: 8, top: 1 }}>
              <i
                onClick={() => {
                  setModalDegerlendir(false);
                }}
                class="bi bi-x-circle"
              ></i>
            </div>
          </ModalHeader>
          <ModalBody>
            <div style={{ textAlign: "center" }}>
              <i
                class={yildizDurum > 0 ? "bi bi-star-fill" : "bi bi-star"}
                style={{
                  cursor: "pointer",
                  fontSize: 30,
                  marginRight: 20,
                  color: yildizDurum > 0 ? "#ff9400" : "",
                }}
                onClick={() => setYildizDurum(1)}
              ></i>
              <i
                class={yildizDurum > 1 ? "bi bi-star-fill" : "bi bi-star"}
                style={{
                  cursor: "pointer",
                  fontSize: 30,
                  marginRight: 20,
                  color: yildizDurum > 1 ? "#ff9400" : "",
                }}
                onClick={() => setYildizDurum(2)}
              ></i>
              <i
                class={yildizDurum > 2 ? "bi bi-star-fill" : "bi bi-star"}
                style={{
                  cursor: "pointer",
                  fontSize: 30,
                  marginRight: 20,
                  color: yildizDurum > 2 ? "#ff9400" : "",
                }}
                onClick={() => setYildizDurum(3)}
              ></i>
              <i
                class={yildizDurum > 3 ? "bi bi-star-fill" : "bi bi-star"}
                style={{
                  cursor: "pointer",
                  fontSize: 30,
                  marginRight: 20,
                  color: yildizDurum > 3 ? "#ff9400" : "",
                }}
                onClick={() => setYildizDurum(4)}
              ></i>
              <i
                class={yildizDurum > 4 ? "bi bi-star-fill" : "bi bi-star"}
                style={{
                  cursor: "pointer",
                  fontSize: 30,
                  marginRight: 20,
                  color: yildizDurum > 4 ? "#ff9400" : "",
                }}
                onClick={() => setYildizDurum(5)}
              ></i>
            </div>
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Button
                disabled={yildizDurum == 0}
                onClick={() => {
                  urundegerlendir();
                }}
              >
                {/* Değerlendir */}
                {common.selectedDilItems.degerlendirmeBtnTxt}
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <div className="col-md-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          {urunDetay.fotolar && (
            <SwiperUrunDetayFoto data={urunDetay.fotolar} id={"urunFotolar"} />
          )}

          {!urunDetay.fotolar && (
            <img
              src={urunDetay.foto}
              // src="http://localhost:20915/File/c8164082-41ec-47c5-a6c0-8068dc91b2f0.jpeg"
              style={{
                width: "100%",
                height: currentHeight,
                objectFit: "cover",
                marginTop: 7,
              }}
            />
          )}
        </div>
        <div
          className="mainUstContainer"
          style={{
            marginTop: 0,
            width: "98%",
            marginLeft: "1%",
            marginRight: "1%",
          }}
        >
          <div className="col-md-12 col-12 urunDetayBox">
            <div style={{ fontWeight: "bold" }}>
              {urunDetay.adi}

              {common.hizmetTipi == "masadan" && (
                <>
                  <Button
                    className="rightBtnGrp"
                    style={{
                      float: "right",
                      backgroundColor: "transparent",
                      borderWidth: 0,
                    }}
                    onClick={() => {
                      if (!user.accessToken) {
                        history.push("/login");
                      } else {
                        setModalDegerlendir(true);
                      }
                    }}
                  >
                    <i
                      className="bi bi-heart-fill"
                      style={{ fontSize: 25, color: "#f6192a" }}
                    ></i>
                  </Button>
                </>
              )}

              <Button
                className="rightBtnGrp"
                style={{
                  float: "right",
                  backgroundColor: "transparent",
                  borderWidth: 0,
                }}
                onClick={() => toogleSet(urunDetay)}
              >
                <i
                  className="bi bi-person-heart"
                  style={{ fontSize: 25, color: "#f6192a" }}
                ></i>
              </Button>
            </div>
            <div
              className="urunDetayBoxBorder"
              style={{
                fontWeight: "bold",
                color: "#979696",
                fontSize: 13,
                width: "100%",
              }}
            >
              {urunDetay.kalori} kcal
            </div>

            <div
              style={{
                color: "#979696",
                marginTop: 5,
              }}
            >
              <i class="bi bi-stopwatch"></i> {urunDetay.hazirlanmaSuresi}
            </div>
            <div
              style={{
                width: "100%",
                marginBottom: 0,
                display: "inline-block",
              }}
            >
              {urunDetay.puan > 0 && (
                <div
                  style={{
                    color: "#979696",
                    marginTop: 5,
                    float: "left",
                  }}
                >
                  <i
                    class="bi bi-star-fill"
                    style={{
                      color: "#ff9400",
                      marginRight: 3,
                    }}
                  ></i>{" "}
                  {urunDetay.puan.toFixed(1)} Puanlama -{" "}
                  {urunDetay.degerlendirmeSayisi} Kişi
                </div>
              )}
              <div
                style={{
                  float: "right",
                  color: "#f6192a",
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {currentUrunFiyat} TL
              </div>
            </div>
          </div>

          <div
            className="col-md-12"
            style={{
              marginTop: 10,
              marginRight: 10,
              backgroundColor: "#fff",
              minHeight: 110,
              borderRadius: 10,
              padding: 10,
              display: "inline-block",
            }}
          >
            <div
              style={{
                height: currentHeight / 1.5,
                objectFit: "cover",
                float: "left",
                marginRight: "2%",
                width: "30%",
              }}
            >
              <img
                src={urunDetay.foto}
                // src="http://localhost:20915/File/c8164082-41ec-47c5-a6c0-8068dc91b2f0.jpeg"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  float: "left",
                  marginRight: "2%",
                }}
              />
            </div>

            <div style={{ width: "68%", float: "left" }}>
              <div>{urunDetay.adi}</div>
              <div style={{ fontSize: 12, color: "#999797" }}>
                {urunDetay.icindekiler}
              </div>
              {selectedUrunFiyatFull.isMenu && (
                <div style={{ fontSize: 12, color: "#999797" }}>
                  {selectedUrunFiyatFull.menuIcindekiYanUrunlerString}
                </div>
              )}
              <div>
                <select
                  className="urunDetaySelect"
                  onChange={(event) => {
                    setSelectedUrunFiyat(event.target.value);
                  }}
                  style={{
                    float: "left",
                    paddingLeft: 5,
                    paddingRight: 5,
                    fontSize: 19,
                    marginRight: 15,
                  }}
                >
                  {urunDetay.urunFiyatlari.map((fiyat) => {
                    return (
                      <option value={fiyat.id}>
                        {fiyat.adet} {fiyat.birimAdi}
                      </option>
                    );
                  })}
                </select>
                <div
                // className="urunDetayNumber"
                // style={{
                //   float: "left",
                //   marginTop: 12,
                //   marginRight: 10,
                //   marginBottom: 10,
                //   color: "#f6192a",
                //   paddingLeft: 10,
                //   paddingRight: 10,
                //   paddingBottom: 5,
                // }}
                >
                  {/* <button
                    className="artiEksiBtn"
                    style={{
                      backgroundColor: "transparent",
                      borderWidth: 0,
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                    onClick={eksiButtonClick}
                  >
                    -
                  </button>
                  {urunCount}
                  <button
                    className="artiEksiBtn"
                    style={{
                      backgroundColor: "transparent",
                      borderWidth: 0,
                      marginLeft: 10,
                      cursor: "pointer",
                    }}
                    onClick={artiButtonClick}
                  >
                    +
                  </button> */}
                  {common.hizmetTipi == "masadan" && (
                    <>
                      <select
                        className="urunDetaySelect"
                        onChange={(event) => {
                          setUrunCount(event.target.value);
                        }}
                        style={{
                          float: "left",
                          paddingLeft: 10,
                          paddingRight: 10,
                          fontSize: 19,
                        }}
                      >
                        <option value={"1"} selected>
                          1
                        </option>
                        <option value={"2"}>2</option>
                        <option value={"3"}>3</option>
                        <option value={"4"}>4</option>
                        <option value={"5"}>5</option>
                        <option value={"6"}>6</option>
                        <option value={"7"}>7</option>
                        <option value={"8"}>8</option>
                        <option value={"9"}>9</option>
                      </select>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {urunDetay.yanUrunVarmi && (
            <div
              className="col-md-12"
              style={{
                marginTop: 10,
                marginRight: 10,
                backgroundColor: "#fff",
                minHeight: 110,
                borderRadius: 10,
                padding: 10,
                display: "inline-block",
                paddingBottom: 20,
              }}
            >
              <h5
                className="yanUrunH5"
                style={{
                  fontSize: 11,
                  paddingBottom: 5,
                }}
              >
                {/* Yan Ürünler */}
                {common.selectedDilItems.yanUrunlerTxt}
              </h5>
              {urunDetay.urunYanUrunleri.map((yan) => {
                return (
                  <div
                    style={{
                      width:
                        width > 1200 ? "50%" : width > 800 ? "50%" : "100%",
                      float: "left",
                      color: "rgb(155 155 155)",
                      fontSize: 14,
                    }}
                  >
                    <input
                      className="yanUrunCheckBox"
                      type="checkbox"
                      style={{ marginTop: 0, marginRight: 5 }}
                      onChange={() => {
                        let yanUrunVarmi = _.find(eklenenYanUrunler, {
                          id: yan.id,
                        });
                        if (yanUrunVarmi) {
                          let newYanUrunList = eklenenYanUrunler.filter(
                            (ur) => ur.id !== yan.id
                          );
                          setEklenenYanUrunler(newYanUrunList);
                        } else {
                          setEklenenYanUrunler([...eklenenYanUrunler, yan]);
                        }
                      }}
                    />
                    {yan.adi}
                    <span className="yanUrunCheckBoxSpan">+{yan.fiyat} TL</span>
                  </div>
                );
              })}
            </div>
          )}

          <div
            className="col-md-12"
            style={{
              marginRight: 10,
              backgroundColor: "#fff",
              //   minHeight: 70,
              borderRadius: 10,
              //   padding: 0,
              display: "inline-block",
              marginTop: 10,
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 10,
            }}
          >
            {urunDetay.urunOzellikleri && (
              <div
                className="col-md-12"
                style={{
                  backgroundColor: "#fff",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                {urunDetay.urunOzellikleri.length > 0 && (
                  <>
                    <p>{common.selectedDilItems.urunOzellikTxt}</p>
                  </>
                )}

                {urunDetay.urunOzellikleri.map((ozel) => {
                  return (
                    <div
                      style={{
                        width:
                          width > 1200 ? "33%" : width > 800 ? "50%" : "100%",
                        float: "left",
                        color: "rgb(155 155 155)",
                        fontSize: 14,
                        marginTop: 0,
                        marginBottom: 5,
                      }}
                    >
                      {common.hizmetTipi == "masadan" && (
                        <>
                          <input
                            className="yanUrunCheckBox"
                            type="checkbox"
                            style={{ marginTop: 0, marginRight: 5 }}
                            onChange={(e) => {
                              debugger;
                              if (e.target.checked) {
                                // if (siparisNotu) {
                                //   if (siparisNotu == " ") {
                                //     setSiparisNotu(ozel.digerDilAdi);
                                //   } else {
                                //     setSiparisNotu(
                                //       siparisNotu + " " + ozel.digerDilAdi
                                //     );
                                //   }
                                // } else {
                                //   setSiparisNotu(ozel.digerDilAdi);
                                // }
                                setSiparisNotList([...siparisNotList, ozel]);
                              } else {
                                // if (siparisNotu) {
                                //   setSiparisNotu(siparisNotu.replace(ozel, ""));
                                // } else {
                                //   setSiparisNotu("");
                                // }
                                let newSip = siparisNotList.filter(
                                  (n) => n.id != ozel.id
                                );
                                setSiparisNotList(newSip);
                              }
                            }}
                          />
                        </>
                      )}
                      {ozel.digerDilAdi}
                    </div>
                  );
                })}
              </div>
            )}
            {common.hizmetTipi == "masadan" && (
              <>
                <div
                  className="col-md-5"
                  style={{ marginBottom: 10, float: "left" }}
                >
                  <textarea
                    className="from-control urunSipNotInput"
                    style={{
                      marginTop: 20,
                      width: "100%",
                      paddingLeft: 10,
                    }}
                    placeholder={common.selectedDilItems.siparisNotuTxt}
                    onChange={(e) => setSiparisNotu(e.target.value)}
                    value={siparisNotu}
                  />
                </div>
              </>
            )}
            <div
              className="col-md-12"
              style={{ marginTop: 20, textAlign: "right", float: "left" }}
            >
              {common.hizmetTipi == "masadan" && (
                <>
                  <Button
                    color="danger"
                    style={{ marginRight: 10 }}
                    onClick={() => siparisVer()}
                    disabled={!urunDetay.satilabilirMi}
                  >
                    {/* Sepete ekle */}
                    {common.selectedDilItems.sepeteEkleBtnTxt}
                  </Button>
                </>
              )}
              <Button
                color="secondary"
                style={{ marginRight: 10 }}
                onClick={() => history.push("/urunler/0")}
              >
                {/* Vazgeç */}
                {common.selectedDilItems.vazgecBtnTxt}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default UrunDetay;
