import React, { useEffect, useRef, useState } from "react";
import "swiper/swiper.min.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
  EffectCoverflow,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory } from "react-router-dom";

import {
  MdKeyboardArrowRight as RightIcon,
  MdKeyboardArrowLeft as LeftIcon,
} from "react-icons/md";
import useWindowDimensions from "./windowDimensions";
SwiperCore.use([Navigation, Pagination, Lazy, A11y, Scrollbar, Autoplay]);

export default function SwiperUrunGrubus({ data, id }) {
  const { width, height } = useWindowDimensions();
  const [allSlides, setAllSlides] = useState([]);

  const history = useHistory();

  useEffect(() => {
    let newHeight = 0;
    if (width > 1200) {
      newHeight = width / 10;
    } else if (width > 1000) {
      newHeight = width / 10;
    } else if (width > 800) {
      newHeight = width / 10;
    } else if (width > 600) {
      newHeight = width / 10;
    } else {
      newHeight = width / 6;
    }
    const slides = [];
    data.map((dd) => {
      slides.push(
        <SwiperSlide key={dd.id} className="urunSwiper swiperBorder">
          <button
            style={{
              width: "100%",
              borderWidth: 0,
              backgroundColor: "transparent",
              paddingLeft: 2,
              paddingRight: 2,
            }}
            onClick={() => history.push("/urunler/" + dd.id)}
          >
            <img
              src={dd.foto}
              alt=""
              style={{
                width: "100%",
                height: newHeight + 10,
                borderRadius: 5,
                objectFit: "cover",
              }}
            />
            <div
              style={{
                textAlign: "center",
                fontSize: width > 320 ? 10 : 8,
                fontWeight: "600",
                marginTop: 10,
              }}
            >
              {dd.adi} 
            </div>
          </button>
        </SwiperSlide>
      );
    });
    setAllSlides(slides);
  }, []);
  if (allSlides.length == 0) {
    return <div>...</div>;
  } else {
    return (
      <Swiper
        slidesPerView={width > 800 ? 4 : 3}
        spaceBetween={5}
        modules={[Pagination]}
        pagination={{ dynamicBullets: true, clickable: true }}
        navigation
        lazy
        autoplay={true}
        scrollbar={{ draggable: true }}
        className="mySwiper"
        id={id}
      >
        {allSlides}
      </Swiper>
    );
  }
}
