import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { getUrunGrubus } from "../services/commonService";
import useWindowDimensions from "../components/windowDimensions";
// import 'intro.js/introjs.css';

import { Steps } from "intro.js-react";

import { setReklamGecildiMi } from "../store/reducers/commonSlice";
const ReklamSayfasi = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  const history = useHistory();

  const [currentHeight, setCurrentHeight] = useState(0);
  const [enabled, setEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const onExit = () => {
    setEnabled(false);
    dispatch(setReklamGecildiMi(true));
  };
  //

  const steps = [
    {
      // element: '#help',
      // intro: '<img src="./assets/Reklam/1.jpg"/>',
      element: document.querySelector("#step0"),
      intro:
        '<div style="width:100%;height:100%;position:absolute;left:0;top:0" id="step0">' +
        '<img onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" src="./assets/Reklam/1.jpg" style="width: 100%;height:100%;object-fit:contain"/>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" class="ileribtn">İleri</button>' +
        '<button onclick="document.getElementsByClassName(\'introjs-skipbutton\')[0].click()" class="reklamiAtlabtn">X</button>' +
        "</div>",
      // position: 'right',
    },
    {
      // element: '#about',
      element: document.querySelector("#step1"),
      intro:
        '<div style="width:100%;height:100%;position:absolute;left:0;top:0"  id="step1">' +
        '<img onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" src="./assets/Reklam/2.jpg" style="width: 100%;height:100%;object-fit:contain"/>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" class="ileribtn">İleri</button>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-prevbutton\')[0].click()" class="geribtn">Geri</button>' +
        '<button onclick="document.getElementsByClassName(\'introjs-skipbutton\')[0].click()" class="reklamiAtlabtn">X</button>' +
        "</div>",
    },
    {
      element: document.querySelector("#step2"),

      intro:
        '<div style="width:100%;height:100%;position:absolute;left:0;top:0"  id="step2">' +
        '<img onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" src="./assets/Reklam/3.jpg" style="width: 100%;height:100%;object-fit:contain"/>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" class="ileribtn">İleri</button>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-prevbutton\')[0].click()" class="geribtn">Geri</button>' +
        '<button onclick="document.getElementsByClassName(\'introjs-skipbutton\')[0].click()" class="reklamiAtlabtn">X</button>' +
        "</div>",
    },
    {
      element: document.querySelector("#step3"),

      intro:
        '<div style="width:100%;height:100%;position:absolute;left:0;top:0" id="step3">' +
        '<img  onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" src="./assets/Reklam/4.jpg" style="width: 100%;height:100%;object-fit:contain"/>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-nextbutton\')[0].click()" class="ileribtn">İleri</button>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-prevbutton\')[0].click()" class="geribtn">Geri</button>' +
        '<button onclick="document.getElementsByClassName(\'introjs-skipbutton\')[0].click()" class="reklamiAtlabtn">X</button>' +
        "</div>",
    },
    {
      element: document.querySelector("#step4"),

      intro:
        '<div style="width:100%;height:100%;position:absolute;left:0;top:0" id="step4">' +
        '<img src="./assets/Reklam/5.jpg" style="width: 100%;height:100%;object-fit:contain"/>' +
        // '<button onclick="document.getElementsByClassName(\'introjs-prevbutton\')[0].click()" class="geribtn">Geri</button>' +
        '<button onclick="document.getElementsByClassName(\'introjs-skipbutton\')[0].click()" class="reklamiAtlabtn">X</button>' +
        "</div>",
    },
  ];
  const currentStepRef = useRef(0);
  const stepsRef = useRef();
  useEffect(() => {
    let timeoutId = setInterval(() => {
      if (5 > currentStepRef.current) {
        document.getElementsByClassName("introjs-nextbutton")[0].click();
      } else {
        onExit();
      }
    }, 3000);
    return () => {
      clearInterval(timeoutId);
    };
  }, []);
  useEffect(() => {
    let newHeight = 0;
    if (width > 1200) {
      newHeight = width / 9;
    } else if (width > 1000) {
      newHeight = width / 9;
    } else if (width > 800) {
      newHeight = width / 9;
    } else if (width > 600) {
      newHeight = width / 9;
    } else {
      newHeight = width / 4;
    }
    setCurrentHeight(newHeight);
  }, []);
  return (
    <>
      {/* <div style={{ width: "100%", height: "100%" }}> */}
      <Steps
        ref={stepsRef}
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        options={{
          nextLabel: "İleri",
          prevLabel: "Geri",
          skipLabel: "X",
        }}
        onChange={(nextStepIndex, nextElement) => {
          console.log(nextStepIndex);
          setCurrentStep(nextStepIndex);
        }}
        onBeforeChange={(nextStepIndex) => {
          setCurrentStep(nextStepIndex);
        }}
        highlightClass={{
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
        }}
      />
      {/* <div id="buttonRow">
          <button id="help">Help</button>
          <button id="about">About</button>
          <button id="contact">Contact Us</button>
        </div> */}
      <div
        style={{
          position: "absolute",
          bottom: 20,
          // width: 50, height: 50,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          // left: "46.5%",
          width: width > 800 ? "70%" : "100%",
          textAlign: "center",
          zIndex: 999,
          left: width > 800 ? null : 0,
          // backgroundColor: "#fff"
        }}
      >
        {steps.map((s, index) => {
          // { index == currentStep && <div>393993</div> }
          // { index != currentStep && <div>.</div> }
          return (
            <div>
              {index == currentStep && (
                <div
                  style={{
                    width: 16,
                    height: 16,
                    marginLeft: 5,
                    backgroundColor: "#fff",
                    borderRadius: 8,
                  }}
                ></div>
              )}
              {index != currentStep && (
                <div
                  style={{
                    width: 10,
                    height: 10,
                    marginLeft: 5,
                    backgroundColor: "#fff",
                    borderRadius: 5,
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </div>
      {/* </div> */}
    </>
  );
};

export default ReklamSayfasi;
