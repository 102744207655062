import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Button } from "reactstrap";
import { updateProfil } from "../store/reducers/userSlice";
import useWindowDimensions from "../components/windowDimensions";
import { useAlert } from "react-alert";

function ProfilEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);

  const { width, height } = useWindowDimensions();
  const alert = useAlert();

  const schema = yup.object().shape({
    adi: yup
      .string()
      .required(common.selectedDilItems.zorunluAlanTxt)
      .min(3, "Min 3"),
    soyadi: yup
      .string()
      .required(common.selectedDilItems.zorunluAlanTxt)
      .min(3, "Min 3"),
    eposta: yup.string().email("Email düzgün formatta değil").nullable(),
    // .required("Email zorunludur"),
  });

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      adi: user.userData.adi,
      soyadi: user.userData.soyadi,
      eposta: user.userData.eposta,
    },
    resolver: yupResolver(schema),
  });

  const {
    reset,
    watch,
    control,
    onChange,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = methods;
  //   const { isValid, dirtyFields, errors } = formState;
  const [submitting, setSubmitting] = useState(false);
  function onSubmit(data) {
    // console.log(errors);
    // console.log(data);
    setSubmitting(true);
    dispatch(updateProfil(data)).then((rest) => {
      setSubmitting(false);
      if (rest) {
        alert.show(common.selectedDilItems.profilGuncellendiTxt, {
          type: "error",
          timeout: 3000,
        });
        history.push("/");
      } else {
        alert.show(common.selectedDilItems.islemSirasindaHataOlustuTxt, {
          type: "error",
          timeout: 3000,
        });
      }
    });
  }

  useEffect(() => {}, []);
  return (
    <div className="col-md-12">
      <div
        className="col-md-12"
        style={{
          backgroundColor: "#fff",
          paddingBottom: 20,
          borderRadius: 20,
          minHeight: height - 100,
        }}
      >
        <h5
          style={{
            fontWeight: "bold",
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 10,
          }}
        >
          Profil
        </h5>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>{common.selectedDilItems.adTxt}</label>
            <Controller
              defaultValue={""}
              name="adi"
              control={control}
              render={({ field }) => <Input {...field} id="adi" name="adi" />}
            />
            {errors.adi && <p className="invalid">{errors.adi.message}</p>}
          </div>
          <div className="form-group">
            <label>{common.selectedDilItems.soyadTxt}</label>
            <Controller
              defaultValue={""}
              name="soyadi"
              control={control}
              render={({ field }) => (
                <Input {...field} id="soyadi" name="soyadi" />
              )}
            />
            {errors.soyadi && (
              <p className="invalid">{errors.soyadi.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>{common.selectedDilItems.emailTxt}</label>
            <Controller
              defaultValue={""}
              name="eposta"
              control={control}
              render={({ field }) => (
                <Input {...field} id="eposta" name="eposta" />
              )}
            />
            {errors.eposta && (
              <p className="invalid">{errors.eposta.message}</p>
            )}
          </div>
          <div className="form-group">
            <Button
              type="submit"
              color="danger"
              disabled={submitting}
              style={{
                width: "100%",
                backgroundColor: "#f6192a",
                marginTop: 20,
              }}
            >
              Kaydet
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfilEdit;
