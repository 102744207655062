import React, { useState, useEffect } from "react";
import useWindowDimensions from "../components/windowDimensions";
import { useHistory, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGarsonCagir, getTemizlikTalebi } from "../store/reducers/userSlice";
import { useAlert } from "react-alert";
import parse from "html-react-parser";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
function Islemler() {
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const alert = useAlert();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalHeaderMessage, setModalHeaderMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [submittingGarson, setSubmittingGarson] = useState(false);
  const [submittingMasaTemizlik, setSubmittingMasaTemizlik] = useState(false);

  useEffect(() => {}, []);

  function garsonCagir() {
    setSubmittingGarson(true);
    dispatch(getGarsonCagir()).then((rest) => {
      setTimeout(() => {
        setSubmittingGarson(false);
      }, 10000);

      if (rest) {
        setModal(!modal);
        setModalHeaderMessage(
          "<div style='text-align:center;'> <img src='./assets/novi.png' style='width:50px; margin-right:10px;'/> " +
            common.selectedDilItems.garsonCagirTxt +
            " </div>"
        );
        setModalMessage(
          "<div style='text-align: center;color: #a39d9d;'>" +
            common.selectedDilItems.garsonCagirMesajTxt +
            "</div>"
        );
        // alert.show("Masaya ait garson en kısa sürede sizinle ilgilenecektir.", {
        //   type: "success",
        //   timeout: 4000,
        // });
      } else {
        setModalHeaderMessage(
          "<div style='text-align:center;'> <img src='./assets/novi.png' style='width:50px; margin-right:10px;'/>  " +
            common.selectedDilItems.garsonCagirTxt +
            " </div>"
        );
        setModalMessage(
          "<div style='text-align: center;color: #a39d9d;'>" +
            common.selectedDilItems.islemSirasindaHataOlustuTxt +
            "</div>"
        );
        // alert.show(
        //   "İşlem sırasında bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
        //   {
        //     type: "error",
        //     timeout: 4000,
        //   }
        // );
      }
    });
  }
  function masaTemizlikTalebi() {
    setSubmittingMasaTemizlik(true);
    dispatch(getTemizlikTalebi()).then((rest) => {
      setTimeout(() => {
        setSubmittingMasaTemizlik(false);
      }, 10000);
      if (rest) {
        setModal(!modal);
        setModalHeaderMessage(
          "<div style='text-align:center;'> <img src='./assets/novi.png' style='width:50px; margin-right:10px;'/>  " +
            common.selectedDilItems.masaTemizlikTalebiTxt +
            " </div>"
        );
        setModalMessage(
          "<div style='text-align: center;color: #a39d9d;'>" +
            common.selectedDilItems.masaTemizlikTalebiMesajTxt +
            "</div>"
        );

        // alert.show(
        //   "Masa temizlik talebiniz alınmıştır. En kısa sürede masanız temizlenecektir.",
        //   {
        //     type: "success",
        //     timeout: 4000,
        //   }
        // );
      } else {
        setModalHeaderMessage(
          "<div style='text-align:center;'> <img src='./assets/novi.png' style='width:50px; margin-right:10px;'/>  " +
            common.selectedDilItems.masaTemizlikTalebiTxt +
            " </div>"
        );
        setModalMessage(
          "<div style='text-align: center;color: #a39d9d;'>" +
            common.selectedDilItems.islemSirasindaHataOlustuTxt +
            "</div>"
        );
        // alert.show(
        //   "İşlem sırasında bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
        //   {
        //     type: "error",
        //     timeout: 4000,
        //   }
        // );
      }
    });
  }
  return (
    <div
      style={{
        width: "90%",
        marginLeft: "5%",
        backgroundColor: "#fff",
        minHeight: height - 100,
      }}
    >
      <Modal isOpen={modal} centered={true}>
        <ModalHeader>
          {parse(modalHeaderMessage)}
          <div style={{ position: "absolute", right: 8, top: 1 }}>
            <i onClick={() => setModal(false)} class="bi bi-x-circle"></i>
          </div>
        </ModalHeader>
        <ModalBody>{parse(modalMessage)}</ModalBody>
      </Modal>

      <div className="row" style={{ marginTop: 20, padding: 15 }}>
        <div className="col-md-12">
          <h2>{common.selectedDilItems.altCafeAdiTxt}</h2>
          {/* <h5 style={{ color: "#ddd" }}>Karaman,Ankara</h5> */}

          <div
            className="islemlerDiv"
            style={{
              width: "100%",
              paddingBottom: 10,
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            <Button
              style={{
                textAlign: "left",
                backgroundColor: submittingGarson ? "#f6192a" : "transparent",
                borderWidth: 0,
                width: "100%",
                color: submittingGarson ? "#fff" : "#000",
              }}
              color="secondary"
              onClick={() => garsonCagir()}
              disabled={submittingGarson}
            >
              <i
                class="bi bi-receipt"
                style={{ fontSize: 20, marginRight: 10 }}
              ></i>{" "}
              {common.selectedDilItems.garsonCagirTxt}
            </Button>
          </div>
          <div
            className="islemlerDiv"
            style={{
              width: "100%",
              paddingBottom: 10,
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            <Button
              style={{
                textAlign: "left",
                backgroundColor: submittingMasaTemizlik
                  ? "#f6192a"
                  : "transparent",
                borderWidth: 0,
                width: "100%",
                color: submittingMasaTemizlik ? "#fff" : "#000",
              }}
              disabled={submittingMasaTemizlik}
              color="secondary"
              onClick={() => masaTemizlikTalebi()}
            >
              <i
                class="bi bi-house"
                style={{ fontSize: 20, marginRight: 10 }}
              ></i>{" "}
              {common.selectedDilItems.masaTemizlikTalebiTxt}
            </Button>
          </div>
          <div
            className="islemlerDiv"
            style={{
              width: "100%",
              paddingBottom: 10,
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            <Button
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                borderWidth: 0,
                width: "100%",
                color: "#000",
              }}
              color="secondary"
              onClick={() => history.push("/dileksikayet")}
            >
              <i
                class="bi bi-pencil"
                style={{ fontSize: 20, marginRight: 10 }}
              ></i>{" "}
              {common.selectedDilItems.dilekSikayetOlusturTxt}
            </Button>
          </div>
          {/* <div
            className="islemlerDiv"
            style={{
              width: "100%",
              paddingBottom: 10,
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            <Button
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                borderWidth: 0,
                width: "100%",
                color: "#000",
              }}
              color="secondary"
            >
              <i
                class="bi bi-pencil"
                style={{ fontSize: 20, marginRight: 10 }}
              ></i>{" "}
              Değerlendirme Yap
            </Button>
          </div> */}
          {/* <div
            className="islemlerDiv"
            style={{
              width: "100%",
              paddingBottom: 10,
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            <Button
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                borderWidth: 0,
                width: "100%",
                color: "#000",
              }}
              color="secondary"
            >
              <i
                class="bi bi-box-arrow-left"
                style={{ fontSize: 20, marginRight: 10 }}
              ></i>{" "}
              Çıkış
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default Islemler;
