import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Spinner } from "reactstrap";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { setUrunList } from "../../store/reducers/urunSlice";
import { musteriTokenVerification } from "../../store/reducers/userSlice";
import {
  getDils,
  setSelectedDilItems,
  getAyarlar,
  setHizmetTipiAdi,
  setGenelAyarlar,
  setReklamGecildiMi,
} from "../../store/reducers/commonSlice";
import QrCodeRead from "../QrCodeRead";
import QrCodeDogrula from "../QrCodeDogrula";

import axios from "axios";
import ReklamSayfasi from "../ReklamSayfasi";
import GarsoniyeSecim from "../GarsoniyeSecim";
import useWindowDimensions from "../../components/windowDimensions";
export function Layout(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const urun = useSelector((state) => state.urun);
  const params = useParams();
  const [waitEnter, setWaitEnter] = useState(true);
  const { width, height } = useWindowDimensions();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [display1, setDisplay1] = useState("none");
  const [display2, setDisplay2] = useState("none");
  const [display3, setDisplay3] = useState("none");
  const [display4, setDisplay4] = useState("none");
  const [display5, setDisplay5] = useState("none");

  const [dilList, setDilList] = useState([]);
  const [selectedDilTag, setSelectedTag] = useState("");
  const [garsoniyeHizmetVarMi, setGarsoniyeHizmetVarMi] = useState();
  const [odemeBtnAcikmi, setOdemeBtnAcikmi] = useState(false);
  useEffect(() => {
    dispatch(getAyarlar()).then((data) => {
      dispatch(setGenelAyarlar(data));
      setGarsoniyeHizmetVarMi(data["garsoniyeHizmetiVarMi"]);
      setOdemeBtnAcikmi(data["masadanSiparisSanalPosKullaniMi"]);
      dispatch(
        setReklamGecildiMi(!data["masadanSiparisGiristeReklamGosterilsinMi"])
      );
      if (data["garsoniyeHizmetiVarMi"] == false) {
        dispatch(setHizmetTipiAdi("masadan"));
      }
    });
  }, []);

  useEffect(() => {
    let timeOut;
    dispatch(getDils(null)).then((data) => {
      setDilList(data.data);

      timeOut = setTimeout(() => {
        setWaitEnter(false);
      }, 1500);
    });
    const urunsJSON = window.localStorage.getItem("selectedUruns");

    if (urunsJSON) {
      const urunsJSONSaat = window.localStorage.getItem("selectedUrunsSaat");
      if (urunsJSONSaat != null) {
        let birSaatSonra = new Date().getTime();
        let urunSaat = parseInt(urunsJSONSaat) + 1000 * 60 * 60;
        if (urunSaat > birSaatSonra) {
          const uruns = JSON.parse(urunsJSON);
          dispatch(setUrunList(uruns));
        }
      }
    }
    let token = window.localStorage.getItem("jwtToken");
    if (token) {
      dispatch(musteriTokenVerification(token));
    }
    //setTimeout(() => {
    //    setWaitEnter(false);
    //}, 1500);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  function changeDil(val) {
    setSelectedTag(val);
    let newDil = dilList.find((o) => o.dilKisaAdi == val);
    dispatch(setSelectedDilItems(newDil));
    axios.defaults.headers.common["lang"] = val;
  }

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedIndex(0);
      setDisplay1("none");
      setDisplay2("none");
      setDisplay3("none");
      setDisplay4("none");
      setDisplay5("none");
    }
    if (location.pathname === "/kesfet") {
      setSelectedIndex(1);
      setDisplay1(width > 400 ? "initial" : "none");
      setDisplay2("none");
      setDisplay3("none");
      setDisplay4("none");
      setDisplay5("none");
    } else if (location.pathname === "/islemler") {
      setSelectedIndex(2);
      setDisplay1("none");
      setDisplay2(width > 400 ? "initial" : "none");
      setDisplay3("none");
      setDisplay4("none");
      setDisplay5("none");
    } else if (location.pathname.startsWith("/siparisler")) {
      setSelectedIndex(3);
      setDisplay1("none");
      setDisplay2("none");
      setDisplay3(width > 400 ? "initial" : "none");
      setDisplay4("none");
      setDisplay5("none");
    } else if (location.pathname === "/profil") {
      setSelectedIndex(4);
      setDisplay1("none");
      setDisplay2("none");
      setDisplay3("none");
      setDisplay4(width > 400 ? "initial" : "none");
      setDisplay5("none");
    } else if (location.pathname === "/odeme") {
      setSelectedIndex(5);
      setDisplay1("none");
      setDisplay2("none");
      setDisplay3("none");
      setDisplay4("none");
      setDisplay5(width > 400 ? "initial" : "none");
    } else {
      setSelectedIndex(0);
      setDisplay1("none");
      setDisplay2("none");
      setDisplay3("none");
      setDisplay4("none");
      setDisplay5("none");
    }
  }, [location]);
  function DilSecimAlan() {
    return (
      <div
        style={{
          float: "left",
          color: "#fff",
        }}
      >
        <select
          onChange={(e) => changeDil(e.target.value)}
          value={selectedDilTag}
          style={{
            backgroundColor: "#f6192a",
            color: "#fff",
            border: "none",
          }}
        >
          {dilList.map((dil) => {
            return <option>{dil.dilKisaAdi}</option>;
          })}
        </select>
      </div>
    );
  }
  return (
    <div>
      {waitEnter && (
        <>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#fff",
              height: window.innerHeight - 1,
            }}
          >
            <img
              src={"./assets/splashscreen.png"}
              style={{
                width: "100%",
                maxWidth: window.width,
                height: window.innerHeight - 1,
                objectFit: "contain",
              }}
            />
            <div
              className="splashLoader"
              style={{ position: "absolute", bottom: 20 }}
            >
              <Spinner
                animation="border"
                variant="light"
                style={{ color: "#d02736" }}
              />
            </div>
          </div>
        </>
      )}

      {!waitEnter &&
        common.masaId == 0 &&
        !location.pathname.includes("/Qr") && (
          <>
            <QrCodeRead />
            <DilSecimAlan />
          </>
        )}
      {!waitEnter &&
        common.masaId == 0 &&
        location.pathname.includes("/Qr") && (
          <>
            <QrCodeDogrula qrvalue={location.pathname} />
            <DilSecimAlan />
          </>
        )}
      {common.reklamGecildiMi == false && common.masaId > 0 && (
        <>
          <Container>
            {!waitEnter && (
              <>
                <ReklamSayfasi />
              </>
            )}
          </Container>
        </>
      )}
      {garsoniyeHizmetVarMi &&
        common.reklamGecildiMi == true &&
        common.masaId > 0 &&
        common.hizmetTipi == null && (
          <>
            <Container>
              {!waitEnter && (
                <>
                  <GarsoniyeSecim />
                </>
              )}
            </Container>
          </>
        )}
      {((garsoniyeHizmetVarMi == false &&
        common.reklamGecildiMi == true &&
        common.masaId > 0) ||
        (garsoniyeHizmetVarMi &&
          common.hizmetTipi != null &&
          common.reklamGecildiMi == true)) && (
        <>
          <Container>{!waitEnter && <>{props.children}</>}</Container>

          {!waitEnter && (
            <div className="bottomFooterMenu">
              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: 70,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                        marginRight: 5,
                        marginLeft: 5,
                        color: "#fff",
                      }}
                    >
                      <Link to="/kesfet">
                        <button
                          className={
                            selectedIndex == 1
                              ? "btnBottomMenu btnBottomMenuActive"
                              : "btnBottomMenu"
                          }
                        >
                          {/* <i
                            className="bi bi-compass"
                            style={{ fontSize: 25 }}
                          ></i> */}
                          <img
                            src="./assets/menu.png"
                            style={{
                              height: width > 400 ? 25 : 18,
                              width: width > 400 ? 28 : 21,
                            }}
                          />

                          <span
                            style={{
                              marginLeft: 10,
                              position: "relative",
                              top: 1,
                              // fontSize: 15,
                              fontSize: width > 400 ? 15 : 9,
                              display: display1,
                            }}
                          >
                            {/* Menü */}
                            {common.selectedDilItems.altMenuTxt}
                          </span>
                        </button>
                      </Link>
                    </div>
                    <div
                      style={{
                        float: "left",
                        marginRight: 5,
                        marginLeft: 5,
                        color: "#fff",
                      }}
                    >
                      <Link to="/islemler">
                        <button
                          className={
                            selectedIndex == 2
                              ? "btnBottomMenu btnBottomMenuActive"
                              : "btnBottomMenu"
                          }
                        >
                          {/* <i
                            className="bi bi-egg-fried"
                            style={{ fontSize: 25 }}
                          ></i> */}
                          <img
                            src="./assets/restoran.png"
                            style={{
                              height: width > 400 ? 25 : 18,
                              width: width > 400 ? 27 : 21,
                            }}
                          />
                          <span
                            style={{
                              marginLeft: 10,
                              position: "relative",
                              top: 0,
                              // fontSize: 15,
                              fontSize: width > 400 ? 15 : 9,
                              display: display2,
                            }}
                          >
                            {/* Voswos  */}
                            {common.selectedDilItems.altCafeAdiTxt}
                          </span>
                        </button>
                      </Link>
                    </div>

                    {(garsoniyeHizmetVarMi == false ||
                      (garsoniyeHizmetVarMi &&
                        common.hizmetTipi == "masadan")) && (
                      <>
                        <div
                          style={{
                            float: "left",
                            marginRight: 5,
                            color: "#fff",
                          }}
                        >
                          <Link to="/siparisler/0">
                            <button
                              className={
                                selectedIndex == 3
                                  ? "btnBottomMenu btnBottomMenuActive"
                                  : "btnBottomMenu"
                              }
                            >
                              {urun.urunList.length > 0 && (
                                <div className="siparisSayisi">
                                  {urun.urunList.length}
                                </div>
                              )}
                              {/* <i className="bi bi-bag" style={{ fontSize: 25 }}></i> */}
                              <img
                                src="./assets/sepet.png"
                                style={{
                                  height: width > 400 ? 25 : 18,
                                  width: width > 400 ? 28 : 21,
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: 10,
                                  position: "relative",
                                  top: 0,
                                  // fontSize: 15,
                                  fontSize: width > 400 ? 15 : 9,
                                  display: display3,
                                }}
                              >
                                {/* Siparişler */}
                                {common.selectedDilItems.altSiparisTxt}
                              </span>
                            </button>
                          </Link>
                        </div>

                        <div
                          style={{
                            float: "left",
                            marginRight: 5,
                            marginLeft: 5,
                            color: "#fff",
                          }}
                        >
                          <Link to="/profil">
                            <button
                              className={
                                selectedIndex == 4
                                  ? "btnBottomMenu btnBottomMenuActive"
                                  : "btnBottomMenu"
                              }
                            >
                              <img
                                src="./assets/profil.png"
                                style={{
                                  height: width > 400 ? 25 : 18,
                                  width: width > 400 ? 26 : 19,
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: 10,
                                  position: "relative",
                                  top: 0,
                                  // fontSize: 15,
                                  fontSize: width > 400 ? 15 : 9,
                                  display: display4,
                                }}
                              >
                                {/* Profil */}
                                {common.selectedDilItems.altProfilTxt}
                              </span>
                            </button>
                          </Link>
                        </div>
                        {odemeBtnAcikmi && (
                          <div
                            style={{
                              float: "left",
                              marginRight: 5,
                              marginLeft: 5,
                              color: "#fff",
                            }}
                          >
                            <Link to="/odeme">
                              <button
                                className={
                                  selectedIndex == 5
                                    ? "btnBottomMenu btnBottomMenuActive"
                                    : "btnBottomMenu"
                                }
                              >
                                <img
                                  src="./assets/odeme2.png"
                                  style={{
                                    height: width > 400 ? 23 : 16,
                                    width: width > 400 ? 28 : 21,
                                    marginTop: -2.5,
                                  }}
                                />
                                <span
                                  style={{
                                    marginLeft: 10,
                                    position: "relative",
                                    top: 0,
                                    // fontSize: 15,
                                    fontSize: width > 400 ? 15 : 9,
                                    display: display5,
                                  }}
                                >
                                  {/* Profil */}
                                  {common.selectedDilItems.odemeYapTxt}
                                </span>
                              </button>
                            </Link>
                          </div>
                        )}
                      </>
                    )}
                    <DilSecimAlan />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
