import React, { useEffect, useRef, useState } from "react";
import "swiper/swiper.min.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  MdKeyboardArrowRight as RightIcon,
  MdKeyboardArrowLeft as LeftIcon,
} from "react-icons/md";
import useWindowDimensions from "./windowDimensions";
import { useHistory } from "react-router-dom";

SwiperCore.use([Navigation, Pagination, Lazy, A11y, Scrollbar]);

export default function SwiperUruns({ data, id }) {
  const { width, height } = useWindowDimensions();
  const [allSlides, setAllSlides] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let newHeight = 0;
    if (width > 1200) {
      newHeight = width / 9;
    } else if (width > 1000) {
      newHeight = width / 9;
    } else if (width > 800) {
      newHeight = width / 9;
    } else if (width > 600) {
      newHeight = width / 9;
    } else {
      newHeight = width / 3;
    }
    const slides = [];
    data.map((dd) => {
      slides.push(
        <SwiperSlide
          key={dd.id}
          className="urunSwiper swiperBorder"
          style={{
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
             minHeight: 185,
          }}
        >
          <div style={{ display: "block", width: "100%" }}>
            <button
              style={{
                width: "100%",
                borderWidth: 0,
                backgroundColor: "transparent",
              }}
              onClick={() => history.push("/urundetay/" + dd.id)}
            >
              <img
                src={dd.foto}
                // src={
                //   "http://localhost:20915/File/c8164082-41ec-47c5-a6c0-8068dc91b2f0.jpeg"
                // }
                alt=""
                style={{
                  width: "100%",
                  height: newHeight - 20,
                  // float: "left",
                  borderRadius: 5,
                  marginRight: 5,
                  objectFit: "cover",
                }}
              />
              {/* {
                dd.alerjikEtkenler &&
                <div
                  style={{
                    fontSize: 12,
                    marginTop: 13,
                    position: "absolute"
                  }}
                >
                  <i class="bi bi-exclamation-triangle-fill"></i>
                </div>
              } */}
              <div
                style={{
                  textAlign: "center",
                  fontSize: width > 320 ? 11 : 9,
                  fontWeight: "600",
                  marginTop: 10,
                }}
              >
                {dd.adi}
                <br />
                {dd.fiyatString} TL
              </div>
            </button>
          </div>
        </SwiperSlide>
      );
    });
    setAllSlides(slides);
  }, []);
  if (allSlides.length == 0) {
    return <div>...</div>;
  } else {
    return (
      <Swiper
        slidesPerView={width > 800 ? 4 : 3}
        spaceBetween={10}
        modules={[Pagination]}
        pagination={{ dynamicBullets: true, clickable: true }}
        navigation
        lazy
        scrollbar={{ draggable: true }}
        autoplay
        className="mySwiper"
        id={id}
      >
        {allSlides}
      </Swiper>
    );
  }
}
