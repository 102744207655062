import React, { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "./screens/shared/Layout";
import { Router } from "react-router-dom";
import { Route } from "react-router";

import history from "./@history";
import { Provider as StoreProvider } from "react-redux";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/AlertTemplate";

import store from "./store";

import "./styles/custom.css";

import Home from "./screens/Home";
import Islemler from "./screens/Islemler";
import Siparisler from "./screens/Siparisler";
import Kategoriler from "./screens/Kategoriler";
import Urunler from "./screens/Urunler";
import UrunDetay from "./screens/UrunDetay";
import Login from "./screens/Login";
import SecimEkrani from "./screens/SecimEkrani";
import DilekSikayet from "./screens/DilekSikayet";
import QrCodeRead from "./screens/QrCodeRead";
import QrCodeDogrula from "./screens/QrCodeDogrula";

import Profil from "./screens/Profil";
import ProfilEdit from "./screens/ProfilEdit";
import OdemeEkrani from "./screens/OdemeEkrani";

import axios from "axios";
import jwtDecode from "jwt-decode";

axios.defaults.baseURL = window.baseAdress;

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 4000,
  offset: "15px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const App = () => {
  // static displayName = App.name;
  return (
    <AlertProvider
      template={AlertTemplate}
      {...options}
      position={positions.TOP_RIGHT}
    >
      <StoreProvider store={store}>
        <Router history={history}>
          <Layout>
            {/* <Route exact path="/" component={SecimEkrani} /> */}
            <Route exact path="/" component={Kategoriler} />
            <Route path="/kesfet" component={Home} />
            <Route path="/islemler" component={Islemler} />
            <Route path="/siparisler/:onay" component={Siparisler} />
            <Route path="/odeme" component={OdemeEkrani} />

            <Route path="/profil" component={Profil} />
            <Route path="/kategoriler" component={Kategoriler} />
            <Route path="/urunler/:katId/:focus?" component={Urunler} />
            <Route path="/urunDetay/:urunId" component={UrunDetay} />
            <Route path="/login" component={Login} />
            <Route path="/dileksikayet" component={DilekSikayet} />
            <Route path="/profiledit" component={ProfilEdit} />
          </Layout>
        </Router>
      </StoreProvider>
    </AlertProvider>
  );
};
export default App;
