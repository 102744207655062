import React, { useEffect, useRef, useState } from "react";
import "swiper/swiper.min.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  MdKeyboardArrowRight as RightIcon,
  MdKeyboardArrowLeft as LeftIcon,
} from "react-icons/md";
import useWindowDimensions from "./windowDimensions";
import { useHistory } from "react-router-dom";

SwiperCore.use([Navigation, Pagination, Lazy, A11y, Scrollbar]);

export default function SwiperUrunDetayFoto({ data, id }) {
  const { width, height } = useWindowDimensions();
  const [allFotos, setAllFotos] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let newHeight = 0;
    if (width > 1200) {
      newHeight = width / 6;
    } else if (width > 1000) {
      newHeight = width / 6;
    } else if (width > 800) {
      newHeight = width / 6;
    } else if (width > 600) {
      newHeight = width / 6;
    } else {
      newHeight = width / 2;
    }
    const photos = [];
    data.map((dd) => {
      photos.push(
        <SwiperSlide
          key={dd.id}
          //   className="urunSwiper swiperBorder"
          style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}
        >
          <div style={{ display: "block", width: "100%" }}>
            <img
              src={dd.foto}
              // src={
              //   "http://localhost:20915/File/c8164082-41ec-47c5-a6c0-8068dc91b2f0.jpeg"
              // }
              alt=""
              style={{
                width: "100%",
                height: newHeight,
                // float: "left",
                borderRadius: 5,
                marginRight: 5,
                objectFit: "cover",
              }}
            />
          </div>
        </SwiperSlide>
      );
    });
    setAllFotos(photos);
  }, []);
  if (allFotos.length == 0) {
    return <div>...</div>;
  } else {
    return (
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        modules={[Pagination]}
        pagination={{ dynamicBullets: true, clickable: false }}
        navigation
        lazy
        scrollbar={{ draggable: false }}
        autoplay
        className="mySwiper"
        id={id}
      >
        {allFotos}
      </Swiper>
    );
  }
}
