import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getSiparislerim } from "./urunSlice";
export const setUserData = (user) => async (dispatch, getState) => {
  dispatch(setUser(user));
};

export const getCurrentUserAction = (token) => async (dispatch, getState) => {
  try {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    // const response = await axios.get(
    //   "/api/MobileUserAccountService/GetCurrentUser"
    // );
    // const data = await response.data;
    // let userVM = {
    //   accessToken: data.Data.Token,
    //   isLogin: true,
    //   userData: data.Data.MobileUserInfo,
    // };
    // dispatch(setUserData(userVM));
    // return data;
  } catch (error) {
    return null;
  }
};
export const updateProfil = (dataVM) => async (dispatch) => {
  const response = await axios.post("/api/ClientCommon/UpdateProfil", dataVM);
  const data = await response.data;
  if (data.result) {
    let newData = {
      accessToken: window.localStorage.getItem("jwtToken"),
      userData: data.data.musteriInfo,
    };

    dispatch(setUser(newData));
    return true;
  } else {
    return false;
  }
};

export const musteriTelDogrulama = (dataVM) => async (dispatch) => {
  try {
    const response = await axios.post(
      "/api/ClientCommon/MusteriTelefonDogrulama",
      dataVM
    );
    const data = await response.data;
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const musteriTelGiris = (dataVM) => async (dispatch) => {
  debugger;
  const response = await axios.post(
    "/api/ClientCommon/MusteriTelefonGiris",
    dataVM
  );
  const data = await response.data;
  if (data.result) {
    let newData = {
      accessToken: data.data.token,
      userData: data.data.musteriInfo,
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + newData.accessToken;

    window.localStorage.setItem("jwtToken", newData.accessToken);

    debugger;
    dispatch(setUser(newData));
  }
  return data;
};

export const getGarsonCagir = (masaId) => async (dispatch, getState) => {
  let masaId = getState().common.masaId;
  const response = await axios.get(
    "/api/ClientCommon/GarsonCagir?masaId=" + masaId
  );
  const data = await response.data;
  if (data.result) {
    return true;
  } else {
    return false;
  }
};
export const getTemizlikTalebi = (masaId) => async (dispatch, getState) => {
  let masaId = getState().common.masaId;
  const response = await axios.get(
    "/api/ClientCommon/MasaTemizlikTalebi?masaId=" + masaId
  );
  const data = await response.data;
  if (data.result) {
    return true;
  } else {
    return false;
  }
};
export const setDilekSikayet = (dataVM) => async (dispatch, getState) => {
  const response = await axios.post(
    "/api/ClientCommon/DilekSikayetPost",
    dataVM
  );
  const data = await response.data;
  return data;
};
export const setUrunDegerlendir =
  (urunId, puan) => async (dispatch, getState) => {
    const response = await axios.get(
      "/api/ClientCommon/UrunDegerlendir?urunId=" + urunId + "&puan=" + puan
    );
    const data = await response.data;
    return data;
  };

export const musteriTokenVerification = (token) => async (dispatch) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const response = await axios.get("/api/ClientCommon/GetCurrentMusteri");
  const data = await response.data;
  if (data.result) {
    let newData = {
      accessToken: token,
      userData: data.data.musteriInfo,
    };

    dispatch(setUser(newData));
    dispatch(getSiparislerim());
    return true;
  } else {
    return false;
  }
};
const initialState = {
  accessToken: null,
  // isLogin: true,
  userData: {
    id: 0,
    adi: null,
    soyadi: null,
  },
};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
