import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Input,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";

import { getOdemeListesi, setOdemeYap } from "../store/reducers/urunSlice";
import useWindowDimensions from "../components/windowDimensions";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";

import { HubConnectionBuilder } from "@microsoft/signalr";

function OdemeEkrani() {
  const dispatch = useDispatch();
  const history = useHistory();
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);

  const { width, height } = useWindowDimensions();
  const alert = useAlert();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [odemeBilgi, setOdemeBilgi] = useState();
  useEffect(() => {
    dispatch(getOdemeListesi()).then((data) => {
      setOdemeBilgi(data.data);
    });
    setValue("yil", new Date().getFullYear());
    setValue("ay", new Date().getMonth() + 1);
  }, [dispatch]);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(
        window.baseAdress + "/mobileUserHub?access_token=" + user.accessToken
      )
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then((result) => {
        console.log("CONNECTED");
      })
      .catch((e) => console.log("Connection failed: ", e));

    connection.on("POSOdeme3DHataOlustu", (error) => {
      history.push("/siparisler/0");
      alert.show(error, {
        type: "error",
        timeout: 5000,
      });
    });

    connection.on("POSOdeme3DOdemeIslemiTamamlandi", () => {
      history.push("/siparisler/0");
      alert.show("Ödeme işlemi başarı ile tamamlandı.", {
        type: "success",
        timeout: 5000,
      });
    });
    return () => {
      connection.off("POSOdeme3DHataOlustu");
      connection.off("POSOdeme3DOdemeIslemiTamamlandi");
    };
  }, []);
  const schema = yup.object().shape({
    kartNo: yup
      .string()
      .required(common.selectedDilItems.zorunluAlanTxt)
      .min(19, "Min 16"),
    kartAdSoyad: yup
      .string()
      .required(common.selectedDilItems.zorunluAlanTxt)
      .min(3, "Min 3"),
    cvv: yup.string().required(common.selectedDilItems.zorunluAlanTxt),
  });
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  function resetForm() {
    setValue("yil", new Date().getFullYear());
    setValue("ay", new Date().getMonth() + 1);
    setValue("cvv", "");
    setValue("kartNo", "");
    setValue("kartAdSoyad", "");
  }
  const {
    reset,
    watch,
    control,
    onChange,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = methods;
  //   const { isValid, dirtyFields, errors } = formState;
  const [submitting, setSubmitting] = useState(false);
  const [borcTipi, setBorcTipi] = useState(1); //1 kendi tutar 2 masa tutari
  const [bankaSonucEkraniAc, setBankaSonucEkraniAc] = useState(false);
  const [ucdThreeDFrame, setUcdThreeDFrame] = useState("");

  function onSubmit(data) {
    data.borcTipi = borcTipi;
    if (data.yil == new Date().getFullYear()) {
      var mm = new Date().getMonth() + 1;
      if (mm > parseInt(data.ay)) {
        alert.show("Kartın bitiş tarihi bu aydan küçük olamaz", {
          type: "error",
          timeout: 3000,
        });
        return;
      }
    }
    setSubmitting(true);
    dispatch(setOdemeYap(data)).then((rest) => {
      setSubmitting(false);
      if (rest.result) {
        setUcdThreeDFrame(rest.data.redirectUrl);
        setBankaSonucEkraniAc(true);
      } else {
        alert.show(rest.message, {
          type: "error",
          timeout: 3000,
        });
      }
    });
  }

  function GetYears() {
    let optArr = [];
    for (let index = 0; index < 30; index++) {
      optArr.push(new Date().getFullYear() + index);
    }
    return optArr.map((op) => {
      if (op == new Date().getFullYear()) {
        return (
          <option value={op.toString()} selected>
            {op}
          </option>
        );
      } else {
        return <option value={op}>{op}</option>;
      }
    });
  }
  if (odemeBilgi == null || odemeBilgi?.toplamKendiTutari == 0) {
    return (
      <div className="col-md-12">
        <div className="col-md-12">
          <div
            className="col-md-12"
            style={{
              backgroundColor: "#fff",
              paddingBottom: 20,
              borderRadius: 20,
              minHeight: height - 100,
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
                marginTop: 20,
                paddingTop: 20,
                paddingBottom: 5,
              }}
            >
              {/* Ödeme Ekranı */}
              {common.selectedDilItems.odemeEkraniTxt}
            </h5>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {!bankaSonucEkraniAc && (
          <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
              <ModalHeader>
                {common.selectedDilItems.tumUrunlerTxt}
                {/* Masadaki Tüm Ürünler */}
                <div style={{ position: "absolute", right: 8, top: 1 }}>
                  <i onClick={() => setModal(false)} class="bi bi-x-circle"></i>
                </div>
              </ModalHeader>
              <ModalBody>
                {odemeBilgi?.masadakiTumUrunler.map((ur) => {
                  return (
                    <div
                      className="row"
                      style={{
                        borderBottom: "1px solid #ddd",
                        paddingBottom: 3,
                        paddingTop: 1,
                      }}
                    >
                      <div
                        className="col-7"
                        style={{ float: "left", textAlign: "left" }}
                      >
                        {ur.urunAdi}
                      </div>
                      <div
                        className="col-5"
                        style={{ float: "right", textAlign: "right" }}
                      >
                        {ur.fiyat} TL
                      </div>
                    </div>
                  );
                })}
              </ModalBody>
            </Modal>
            <div className="col-md-12">
              <div
                className="col-md-12"
                style={{
                  backgroundColor: "#fff",
                  paddingBottom: 20,
                  borderRadius: 20,
                  minHeight: height - 100,
                }}
              >
                <h5
                  style={{
                    fontWeight: "bold",
                    marginTop: 20,
                    paddingTop: 20,
                    paddingBottom: 5,
                  }}
                >
                  {/* Ödeme Ekranı */}
                  {common.selectedDilItems.odemeEkraniTxt}
                </h5>

                {common.genelAyarlar["masadanSiparisSanalPosKullaniMi"] && (
                  <>
                    <div>
                      <Button
                        style={{
                          width: "50%",
                          fontSize: width > 320 ? 10 : 10,
                          backgroundColor:
                            borcTipi == 1 ? "rgb(246, 25, 42)" : "#fff",
                          color: borcTipi == 1 ? "#fff" : "#000",
                          borderTopRightRadius: " 1px",
                          // borderColor:
                          //   borcTipi == 1 ? "rgb(246, 25, 42)" : "#fff",
                          borderColor: "rgb(246, 25, 42)",
                        }}
                        onClick={() => setBorcTipi(1)}
                      >
                        {common.selectedDilItems.hesabimiOdeTxt}{" "}
                        {odemeBilgi?.toplamKendiTutari} TL{" "}
                      </Button>

                      {common.genelAyarlar[
                        "masadanSiparisMasaninTumHesabiniOdeyebilirMi"
                      ] && (
                        <>
                          <Button
                            style={{
                              width: "50%",
                              fontSize: width > 320 ? 10 : 10,
                              backgroundColor:
                                borcTipi == 2 ? "rgb(246, 25, 42)" : "#fff",
                              color: borcTipi == 2 ? "#fff" : "#000",
                              borderTopLightRadius: " 1px",
                              borderColor: "rgb(246, 25, 42)",
                            }}
                            onClick={() => setBorcTipi(2)}
                          >
                            {common.selectedDilItems.masaTutariTxt}{" "}
                            {odemeBilgi?.toplamMasaTutari} TL{" "}
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "block",
                    marginBottom: "11px",
                  }}
                >
                  <Button
                    color="link"
                    style={{
                      float: "right",
                      marginTop: "-5px",
                      fontSize: "11px",
                      color: "rgb(246, 25, 42)",
                      fontWeight: "bold",
                    }}
                    onClick={() => toggle()}
                  >
                    {/* Tüm Ürünler */}
                    {common.selectedDilItems.tumUrunlerTxt}
                  </Button>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group" style={{ marginTop: 5 }}>
                    <label>
                      {common.selectedDilItems.kartNoTxt}
                      {/* Kart No */}
                    </label>
                    <Controller
                      defaultValue={""}
                      name="kartNo"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="input"
                          id="kartNo"
                          name="kartNo"
                          onChange={(event) => {
                            let val = event.target.value;
                            val = val.replace(/\D/g, "");
                            let num = `${val.substring(0, 4)} ${val.substring(
                              4,
                              8
                            )} ${val.substring(8, 12)} ${val.substring(
                              12,
                              16
                            )}`;
                            num = num.trim();
                            field.onChange(num);
                          }}
                        />
                      )}
                    />
                    {errors.kartNo && (
                      <p className="invalid">{errors.kartNo.message}</p>
                    )}
                  </div>
                  <div className="form-group" style={{ marginTop: 5 }}>
                    <label>
                      {/* Kart Üzerindeki İsim */}
                      {common.selectedDilItems.kartUzerindekiIsimTxt}
                    </label>
                    <Controller
                      defaultValue={""}
                      name="kartAdSoyad"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="input"
                          id="kartAdSoyad"
                          name="kartAdSoyad"
                        />
                      )}
                    />
                    {errors.kartAdSoyad && (
                      <p className="invalid">{errors.kartAdSoyad.message}</p>
                    )}
                  </div>
                  <div>
                    <div
                      className="form-group"
                      style={{
                        marginTop: 5,
                        width: "27%",
                        float: "left",
                        marginRight: "3%",
                      }}
                    >
                      <label>
                        {/* Ay */}
                        {common.selectedDilItems.ayTxt}
                      </label>
                      <Controller
                        defaultValue={""}
                        name="ay"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="select"
                            id="ay"
                            name="ay"
                            onChange={(event) => {
                              field.onChange(parseInt(event.target.value));
                            }}
                          >
                            <option value={"1"}>01</option>
                            <option value={"2"}>02</option>
                            <option value={"3"}>03</option>
                            <option value={"4"}>04</option>
                            <option value={"5"}>05</option>
                            <option value={"6"}>06</option>
                            <option value={"7"}>07</option>
                            <option value={"8"}>08</option>
                            <option value={"9"}>09</option>
                            <option value={"10"}>10</option>
                            <option value={"11"}>11</option>
                            <option value={"12"}>12</option>
                          </Input>
                        )}
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{
                        marginTop: 5,
                        width: "37%",
                        float: "left",
                      }}
                    >
                      <label>
                        {/* Yıl */}
                        {common.selectedDilItems.yilTxt}
                      </label>
                      <Controller
                        defaultValue={""}
                        name="yil"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="select"
                            id="yil"
                            name="yil"
                            onChange={(event) => {
                              field.onChange(parseInt(event.target.value));
                            }}
                          >
                            <GetYears />
                          </Input>
                        )}
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{
                        marginTop: 5,
                        width: "30%",
                        float: "left",
                        marginLeft: "3%",
                      }}
                    >
                      <label>
                        {common.selectedDilItems.cvvTxt}
                        {/* CVV */}
                      </label>
                      <Controller
                        defaultValue={""}
                        name="cvv"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="input"
                            id="cvv"
                            name="cvv"
                            onChange={(event) => {
                              let val = event.target.value;
                              val = val.replace(/\D/g, "");
                              let num = `${val.substring(0, 3)}`;
                              num = num.trim();
                              field.onChange(num);
                            }}
                          />
                        )}
                      />
                      {errors.cvv && (
                        <p className="invalid" style={{ fontSize: 8 }}>
                          {errors.cvv.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      width: "90%",
                      marginLeft: "1%",
                      marginTop: "25px",
                      color: "#989697",
                      fontSize: 12,
                      textAlign: "left",
                    }}
                  >
                    <i
                      class="bi bi-info-circle"
                      style={{ color: "#f9172f" }}
                    ></i>
                    <span style={{ marginLeft: 5 }}>
                      {/* 3D Secure ödemeye yönlendirileceksiniz. */}
                      {common.selectedDilItems.threeDSecureTxt}
                    </span>
                  </div>

                  <div className="form-group">
                    <Button
                      type="submit"
                      color="danger"
                      disabled={submitting}
                      style={{
                        width: "100%",
                        backgroundColor: "#f6192a",
                        marginTop: 20,
                      }}
                    >
                      {/* Gönder */}
                      {common.selectedDilItems.gonderTxt}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
        {bankaSonucEkraniAc && (
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              paddingTop: "169%",
            }}
          >
            <iframe
              src={ucdThreeDFrame}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
                minHeight: 600,
              }}
            />
          </div>
        )}
      </>
    );
  }
}

export default OdemeEkrani;
