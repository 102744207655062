import React, { useState, useEffect } from "react";
import { getAnaSayfaItems } from "../services/commonService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { getUrunGrubus } from "../services/commonService";
import useWindowDimensions from "../components/windowDimensions";

const Kategoriler = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const common = useSelector((state) => state.common);
  const [urunGrubus, setUrunGrubus] = useState([]);

  useEffect(() => {
    dispatch(getUrunGrubus()).then((data) => {
      setUrunGrubus(data.data);
    });
  }, [dispatch, common.selectedDilItems]);
  const [currentHeight, setCurrentHeight] = useState(0);
  useEffect(() => {
    let newHeight = 0;
    if (width > 1200) {
      newHeight = width / 9;
    } else if (width > 1000) {
      newHeight = width / 9;
    } else if (width > 800) {
      newHeight = width / 9;
    } else if (width > 600) {
      newHeight = width / 9;
    } else {
      newHeight = width / 4;
    }
    setCurrentHeight(newHeight);
  }, []);
  return (
    <>
      <div
        className="row"
        style={{
          marginBottom: 100,
        }}
      >
        <div className="col-md-12">
          <div
            className="col-md-12"
            style={{
              marginTop: 30,
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "100%",
                marginBottom: 20,
              }}
            >
              <h5 style={{ float: "left", fontWeight: "bold" }}>
                {/* Ürün Kategorileri */}
                {common.selectedDilItems.urunKategorileriTxt} 
              </h5>
            </div>
          </div>
          <div className="col-md-12">
            {urunGrubus.length > 0 &&
              urunGrubus.map((ur, index) => {
                return (
                  <div
                    style={{
                      width: width > 800 ? "31%" : "48%",
                      float: "left",
                      marginRight:
                        width > 800 ? "2%" : index % 2 == 0 ? "2%" : "0%",
                      marginLeft:
                        width > 800 ? "0%" : index % 2 == 1 ? "2%" : "0%",
                      marginBottom: 20,
                    }}
                  >
                    <button
                      style={{
                        width: "100%",
                        borderWidth: 0,
                        backgroundColor: "transparent",
                      }}
                      onClick={() => history.push("/urunler/" + ur.id)}
                    >
                      <img
                        src={ur.foto}
                        style={{
                          width: "100%",
                          height: currentHeight,
                          objectFit: "cover",
                        }}
                      />
                      <div style={{ textAlign: "center" }}>{ur.adi}</div>
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Kategoriler;
