import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Button } from "reactstrap";
import { setDilekSikayet } from "../store/reducers/userSlice";
import useWindowDimensions from "../components/windowDimensions";
import { useAlert } from "react-alert";

function DilekSikayet() {
  const dispatch = useDispatch();
  const history = useHistory();
  const common = useSelector((state) => state.common);
  const { width, height } = useWindowDimensions();
  const alert = useAlert();

  const schema = yup.object().shape({
    //   tipi: yup.number().required("Tip zorunludur"),
    metin: yup
      .string()
      .required(common.selectedDilItems.zorunluAlanTxt)
      .min(3, "Min 3"),
  });
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: { dilekSikayetTipi: 0 },
    resolver: yupResolver(schema),
  });

  const {
    reset,
    watch,
    control,
    onChange,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = methods;
  //   const { isValid, dirtyFields, errors } = formState;
  const [submitting, setSubmitting] = useState(false);
  function onSubmit(data) {
    // console.log(errors);
    // console.log(data);
    setSubmitting(true);
    dispatch(setDilekSikayet(data)).then((rest) => {
      setSubmitting(false);
      if (rest.result) {
        alert.show(common.selectedDilItems.dilekSikayetAlindiTxt, {
          type: "error",
          timeout: 3000,
        });
        history.push("/");
      } else {
        alert.show(rest.message, {
          type: "error",
          timeout: 3000,
        });
      }
    });
  }

  useEffect(() => {}, []);
  return (
    <div className="col-md-12">
      <div
        className="col-md-12"
        style={{
          backgroundColor: "#fff",
          paddingBottom: 20,
          borderRadius: 20,
          minHeight: height - 100,
        }}
      >
        <h5
          style={{
            fontWeight: "bold",
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 10,
          }}
        >
          {/* Dilek/Şikayet */}
          {common.selectedDilItems.dilekSikayetBaslikTxt}
        </h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>
              {/* Dilek/Şikayet Tipi */}
              {common.selectedDilItems.dilekSikayetTipiTxt}
            </label>
            <Controller
              defaultValue={""}
              name="dilekSikayetTipi"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  // {...field}
                  type="select"
                  id="dilekSikayetTipi"
                  name="dilekSikayetTipi"
                  onChange={(event) => {
                    onChange(parseInt(event.target.value));
                  }}
                >
                  <option value={"0"}>
                    {common.selectedDilItems.dilekSikayetTipDilekTxt}
                  </option>
                  <option value={"1"}>
                    {common.selectedDilItems.dilekSikayetTipSikayetTxt}
                  </option>
                </Input>
              )}
            />
            {errors.dilekSikayetTipi && (
              <p className="invalid">{errors.dilekSikayetTipi.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>{common.selectedDilItems.dilekSikayetTipMetinTxt}</label>
            <Controller
              defaultValue={""}
              name="metin"
              control={control}
              render={({ field }) => (
                <Input {...field} type="textarea" id="metin" name="metin" />
              )}
            />
            {errors.metin && <p className="invalid">{errors.metin.message}</p>}
          </div>
          <div className="form-group">
            <Button
              type="submit"
              color="danger"
              disabled={submitting}
              style={{
                width: "100%",
                backgroundColor: "#f6192a",
                marginTop: 20,
              }}
            >
              {common.selectedDilItems.kaydetBtnTxt}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DilekSikayet;
