import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const getQrDogrula = (qrValue) => async (dispatch) => {
  const response = await axios.get(
    "/api/ClientCommon/QrDogrula?qrValue=" + qrValue
  );
  const data = await response.data;
  return data;
};
export const getAyarlar = () => async (dispatch, getState) => {
  const response = await axios.get("/api/ClientCommon/GetAyarlars");
  const data = await response.data;
  return data;
};

export const getDils = (selectedDilTag) => async (dispatch) => {
  const response = await axios.get("/api/ClientCommon/GetDils");
  const data = await response.data;
  if (selectedDilTag) {
    let newDil = data.data.find((o) => o.dilKisaAdi == selectedDilTag);
    dispatch(setSelectedDilItems(newDil));
  } else {
    let newDil = data.data.find((o) => o.dilKisaAdi == "TR");
    dispatch(setSelectedDilItems(newDil));
  }

  return data;
};
export const setGarsoniyeBildirim = (masaId) => async (dispatch) => {
  const response = await axios.get("/api/ClientCommon/GarsoniyeBildirimGonder?masaId=" + masaId);
  const data = await response.data;
  return data;
};

const initialState = {
  baseAddress: "",
  notificationList: [],
  notificationCount: 0,
  siparisTipi: 1,
  // masaId: 1002,
  masaId: 0,
  masaNoAdi: null,
  genelAyarlar: null,
  reklamGecildiMi: false,
  hizmetTipi:null, // hizmet tipi gorsoniyeli veya normal sipariş
  selectedDilItems: null,
};

const commonSlice = createSlice({
  name: "app/common",
  initialState,
  reducers: {
    setMyNotificationList: (state, action) => {
      state.notificationList = action.payload.allData;
      state.notificationCount = action.payload.count;
    },
    setSiparisTipi: (state, action) => {
      state.siparisTipi = action.payload;
    },
    setReklamGecildiMi: (state, action) => {
      state.reklamGecildiMi = action.payload;
    },
    setGenelAyarlar: (state, action) => {
      state.genelAyarlar = action.payload;
    },
    setMasaIdAndNo: (state, action) => {
      state.masaId = action.payload.masaId;
      state.masaAdi = action.payload.masaAdi;
    },
    setSelectedDilItems: (state, action) => {
      state.selectedDilItems = action.payload;
    },
    setHizmetTipiAdi:(state, action) => {
      state.hizmetTipi = action.payload;
    }
  },
  extraReducers: {},
});

export const {
  setMyNotificationList,
  setSiparisTipi,
  setGenelAyarlar,
  setMasaIdAndNo,
  setReklamGecildiMi,
  setSelectedDilItems,
  setHizmetTipiAdi
} = commonSlice.actions;

export default commonSlice.reducer;
