import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { setMasaIdAndNo } from "../store/reducers/commonSlice";
import { QrReader } from "react-qr-reader";
import { getQrDogrula } from "../store/reducers/commonSlice";
import { useAlert } from "react-alert";
function QrCodeRead() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const common = useSelector((state) => state.common);

  const alert = useAlert();
  const [qrOpen, setQrOpen] = useState(false);
  const [qrResult, setQrResult] = useState();
  const [sonuc, setSonuc] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  const [masaInfo, setMasaInfo] = useState({
    masaId: 0,
    masaAdi: "",
  });

  useEffect(() => {
    let masaInfo = window.localStorage.getItem("masaInfo");
    if (masaInfo) {
      let newMas = JSON.parse(masaInfo);
      let eklenmeTarih = addMinutes(newMas.eklenmeTarihi, 10);
      let suan = new Date().getTime();
      if (eklenmeTarih > suan) {
        setMasaInfo(newMas);
      }
    }
  }, []);

  function addMinutes(date, minutes) {
    return new Date(date).getTime() + minutes * 60 * 1000;
  }

  function qrOkundu(code) {
    //masa bilgileri alınır.
    dispatch(getQrDogrula(code)).then((result) => {
      if (result.result) {
        setMasaInfo({
          masaId: result.data.masaId,
          masaAdi: result.data.masaNo,
          eklenmeTarihi: new Date(),
        });
        setModalOpen(true);
      } else {
        // "Qr Doğrulanamadı";
        alert.show(common.selectedDilItems?.qrDogrulanmadiTxt, {
          type: "error",
          timeout: 2000,
        });
      }
    });
  }
  function setMasaStorage() {
    window.localStorage.setItem("masaInfo", JSON.stringify(masaInfo));
    dispatch(setMasaIdAndNo(masaInfo));
    toggle();
    history.push("/");
  }
  return (
    <>
      <Modal
        isOpen={modalOpen}
        toggle={toggle}
        centered={true}
        backdrop={false}
        fade={false}
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          {masaInfo.masaAdi}{" "}
          {common.selectedDilItems.qrOkunduOnayTxt.replace("{masano}", "")}
          {/* ile devam edilecektir, onaylıyormusunuz? */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              setMasaStorage();
            }}
          >
            {common.selectedDilItems.evetTxt}
            {/* Evet */}
          </Button>
          <Button onClick={() => toggle()}>
            {/* Hayır */}
            {common.selectedDilItems.hayirTxt}
          </Button>
        </ModalFooter>
      </Modal>

      <div
        style={{
          width: "100%",
          textAlign: "center",
          backgroundColor: "#f8f6f7",
          height: window.innerHeight - 1 - 25,
        }}
      >
        {!qrOpen && (
          <>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#f8f6f7",
              }}
            >
              <img
                src={"./assets/giris.png"}
                style={{
                  width: "100%",
                  maxWidth: window.width,
                  height: window.innerHeight / 2,
                  objectFit: "contain",
                }}
              />
            </div>
            {masaInfo.masaId > 0 && (
              <div>
                <Button
                  style={{
                    backgroundColor: "#f9172f",
                    color: "#fff",
                    width: "90%",
                    maxWidth: 500,
                    marginBottom: 10,
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    setMasaStorage();
                  }}
                >
                  {masaInfo.masaAdi}{" "}
                  {common.selectedDilItems.qrMasaIleDevamEtTxt}
                  {/* İle Devam Et */}
                </Button>
              </div>
            )}
            <div>
              <Button
                style={{
                  width: "90%",
                  maxWidth: 500,
                  backgroundColor: "#282828",
                  fontWeight: "600",
                }}
                onClick={() => setQrOpen(true)}
              >
                {masaInfo.masaId > 0 ? "Yeni " : ""}
                {common.selectedDilItems.qrKoduOkutTxt}
                {/* QR Kodu Okut */}
              </Button>
            </div>
            <div
              style={{
                width: "90%",
                marginLeft: "5%",
                marginTop: "25px",
                color: "#989697",
                fontSize: 12,
                textAlign: "center",
              }}
            >
              <i class="bi bi-info-circle" style={{ color: "#f9172f" }}></i>
              <span style={{ marginLeft: 5 }}>
                {common.selectedDilItems.qrCodeBilgiTxt}
                {/* Masa üzerinde bulunan qr kodu telefonunuza okuttuktan sonra
                masaya sipariş verebilir, garson çağırabilirisiniz. */}
              </span>
            </div>
          </>
        )}
        {qrOpen && (
          <>
            <div style={{ width: "100%", height: window.innerHeight / 6 }}>
              <h5 style={{ paddingTop: 40, color: "#000" }}>Qr Okut</h5>
            </div>
            <QrReader
              constraints={{
                facingMode: "environment",
              }}
              onResult={(result, error) => {
                if (!!result) {
                  setQrResult(result?.text);
                  qrOkundu(result?.text);
                }

                if (!!error) {
                  //qrOkundu(1002);
                  // setSonuc("Qr Okunamadı");
                }
              }}
              style={{ width: "100%" }}
              videoContainerStyle={{
                width: "100%",
                height: window.innerHeight / 2,
                // position: "unset",
                paddingTop: 0,
                //   paddingTop: window.innerHeight / 3,
              }}
              videoStyle={{
                width: "60%",
                height: window.innerHeight / 2,
                marginLeft: "20%",
              }}
            />
            <div style={{ width: "100%", marginTop: 10 }}>
              <div style={{ paddingTop: 10 }}>
                <div style={{ color: "#fff", fontSize: 13 }}>{sonuc}</div>
                <Button
                  style={{ width: "60%", maxWidth: 500, marginTop: 10 }}
                  onClick={() => setQrOpen(false)}
                >
                  Vazgeç
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default QrCodeRead;
