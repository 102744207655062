const lang = "TR";
export function formatDate(date, dilValues) {
  if (date == null || date == undefined) return "";
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let tempMonth = "";
  if (month == 1) tempMonth = dilValues ? dilValues.ocakAyiTxt : "Ocak";
  else if (month == 2) tempMonth = dilValues ? dilValues.subatAyiTxt : "Şubat";
  else if (month == 3) tempMonth = dilValues ? dilValues.martAyiTxt : "Mart";
  else if (month == 4) tempMonth = dilValues ? dilValues.nisanAyiTxt : "Nisan";
  else if (month == 5) tempMonth = dilValues ? dilValues.mayisAyiTxt : "Mayıs";
  else if (month == 6)
    tempMonth = dilValues ? dilValues.haziranAyiTxt : "Haziran";
  else if (month == 7)
    tempMonth = dilValues ? dilValues.temmuzAyiTxt : "Temmuz";
  else if (month == 8)
    tempMonth = dilValues ? dilValues.agustosAyiTxt : "Ağustos";
  else if (month == 9) tempMonth = dilValues ? dilValues.eylulAyiTxt : "Eylül";
  else if (month == 10) tempMonth = dilValues ? dilValues.ekimAyiTxt : "Ekim";
  else if (month == 11) tempMonth = dilValues ? dilValues.kasimAyiTxt : "Kasım";
  else if (month == 12)
    tempMonth = dilValues ? dilValues.aralikAyiTxt : "Aralık";
  return day + " " + tempMonth + " " + year;
}
export function formatDateNoYear(date, dilValues) {
  if (date == null || date == undefined) return "";
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let tempMonth = "";
  if (month == 1) tempMonth = dilValues ? dilValues.ocakAyiTxt : "Ocak";
  else if (month == 2) tempMonth = dilValues ? dilValues.subatAyiTxt : "Şubat";
  else if (month == 3) tempMonth = dilValues ? dilValues.martAyiTxt : "Mart";
  else if (month == 4) tempMonth = dilValues ? dilValues.nisanAyiTxt : "Nisan";
  else if (month == 5) tempMonth = dilValues ? dilValues.mayisAyiTxt : "Mayıs";
  else if (month == 6)
    tempMonth = dilValues ? dilValues.haziranAyiTxt : "Haziran";
  else if (month == 7)
    tempMonth = dilValues ? dilValues.temmuzAyiTxt : "Temmuz";
  else if (month == 8)
    tempMonth = dilValues ? dilValues.agustosAyiTxt : "Ağustos";
  else if (month == 9) tempMonth = dilValues ? dilValues.eylulAyiTxt : "Eylül";
  else if (month == 10) tempMonth = dilValues ? dilValues.ekimAyiTxt : "Ekim";
  else if (month == 11) tempMonth = dilValues ? dilValues.kasimAyiTxt : "Kasım";
  else if (month == 12)
    tempMonth = dilValues ? dilValues.aralikAyiTxt : "Aralık";
  return day + " " + tempMonth;
}
export function formatTime(time) {
  if (time == null || time == undefined) return "";
  let min = time.getMinutes();
  if (time.getMinutes() >= 0 && time.getMinutes() <= 9) {
    min = "0" + time.getMinutes();
  }
  return time.getHours() + ":" + min;
}

export function formatDateTime(date, dilValues) {
  if (date == null || date == undefined) return "";
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let tempMonth = "";
  if (month == 1) tempMonth = dilValues ? dilValues.ocakAyiTxt : "Ocak";
  else if (month == 2) tempMonth = dilValues ? dilValues.subatAyiTxt : "Şubat";
  else if (month == 3) tempMonth = dilValues ? dilValues.martAyiTxt : "Mart";
  else if (month == 4) tempMonth = dilValues ? dilValues.nisanAyiTxt : "Nisan";
  else if (month == 5) tempMonth = dilValues ? dilValues.mayisAyiTxt : "Mayıs";
  else if (month == 6)
    tempMonth = dilValues ? dilValues.haziranAyiTxt : "Haziran";
  else if (month == 7)
    tempMonth = dilValues ? dilValues.temmuzAyiTxt : "Temmuz";
  else if (month == 8)
    tempMonth = dilValues ? dilValues.agustosAyiTxt : "Ağustos";
  else if (month == 9) tempMonth = dilValues ? dilValues.eylulAyiTxt : "Eylül";
  else if (month == 10) tempMonth = dilValues ? dilValues.ekimAyiTxt : "Ekim";
  else if (month == 11) tempMonth = dilValues ? dilValues.kasimAyiTxt : "Kasım";
  else if (month == 12)
    tempMonth = dilValues ? dilValues.aralikAyiTxt : "Aralık";

  let min = date.getMinutes();
  if (date.getMinutes() >= 0 && date.getMinutes() <= 9) {
    min = "0" + date.getMinutes();
  }
  let hour = date.getHours();
  if (date.getHours() >= 0 && date.getHours() <= 9) {
    hour = "0" + date.getHours();
  }
  return day + " " + tempMonth + " " + year + " " + hour + ":" + min;
}
export function formatDateTimeServer(date) {
  if (date == null || date == undefined) return "";
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  if (month >= 0 && month <= 9) {
    month = "0" + month;
  }

  let min = date.getMinutes();
  if (date.getMinutes() >= 0 && date.getMinutes() <= 9) {
    min = "0" + date.getMinutes();
  }
  let hour = date.getHours();
  if (date.getHours() >= 0 && date.getHours() <= 9) {
    hour = "0" + date.getHours();
  }
  console.log(year + "." + month + "." + day + "T" + hour + ":" + min + ":00");
  return year + "-" + month + "-" + day + "T" + hour + ":" + min + ":00";
}
