import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const setYeniSiparis = (dataVM) => async (dispatch) => {
  const response = await axios.post("/api/ClientCommon/SiparisEkle", dataVM);
  const data = await response.data;
  if (data.result) {
    dispatch(setUrunListWithCache([]));
  }
  return data;
};
export const getSiparislerim = (dataVM) => async (dispatch) => {
  const response = await axios.get("/api/ClientCommon/GetSiparislerim");
  const data = await response.data;
  dispatch(setSiparisList(data.data));
  return data;
};
export const getOdemeListesi = () => async (dispatch) => {
  const response = await axios.get("/api/ClientCommon/GetOdemeListesiClient");
  const data = await response.data;
  return data;
};
export const setOdemeYap = (dataVM) => async (dispatch) => {
  const response = await axios.post("/api/ClientCommon/OdemeYap", dataVM);
  const data = await response.data;
  return data;
};
export const setSiparisIptal = (sipId) => async (dispatch) => {
  const response = await axios.get(
    "/api/ClientCommon/SiparisIptal?sipId=" + sipId
  );
  const data = await response.data;
  return data;
};
const initialState = {
  urunList: [],
  siparisList: [],
};

const urunSlice = createSlice({
  name: "auth/urun",
  initialState,
  reducers: {
    addNewUrun: (state, action) => {
      state.urunList.push(action.payload);
      window.localStorage.setItem(
        "selectedUruns",
        JSON.stringify(state.urunList)
      );
      window.localStorage.setItem("selectedUrunsSaat", new Date().getTime());
    },
    setUrunList: (state, action) => {
      state.urunList = action.payload;
    },
    setUrunListWithCache: (state, action) => {
      state.urunList = action.payload;
      window.localStorage.setItem(
        "selectedUruns",
        JSON.stringify(action.payload)
      );
    },
    setSiparisList: (state, action) => {
      state.siparisList = action.payload;
    },
    deleteUrun: (state, action) => {},
  },
  extraReducers: {},
});

export const {
  addNewUrun,
  deleteUrun,
  setUrunList,
  setUrunListWithCache,
  setSiparisList,
} = urunSlice.actions;

export default urunSlice.reducer;
