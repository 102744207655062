import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import { getUrunler } from "../services/commonService";
import useWindowDimensions from "../components/windowDimensions";
import { getAnaSayfaItems } from "../services/commonService";
import { addNewUrun } from "../store/reducers/urunSlice";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { useAlert } from "react-alert";
const Urunler = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const { katId, focus } = useParams();
  const [urunler, setUrunler] = useState([]);
  const [urunlerFull, setUrunlerFull] = useState([]);
  const [modal, setModal] = useState(false);
  const [alerjikModalText, setAlerjikModalText] = useState("");
  const [alerjikModalHeaderText, setAlerjikModalHeaderText] = useState("");
  const toggle = () => setModal(!modal);
  const common = useSelector((state) => state.common);
  const urun = useSelector((state) => state.urun);

  function extToLower(txt) {
    let newTxt = txt
      .replace("İ", "i")
      .replace("I", "ı")
      .replace("Ü", "ü")
      .replace("Ö", "ö")
      .replace("Ş", "ş")
      .replace("Ç", "ç");
    return newTxt.toLowerCase();
  }
  function toogleSet(ur) {
    setAlerjikModalText(
      ur.alerjikEtkenler.map((y) => {
        return <div>{y}</div>;
      })
    );
    setAlerjikModalHeaderText(
      ur.adi + " " + common.selectedDilItems.alerjikEtkenlerTxt
    );
    setModal(!modal);
  }

  useEffect(() => {
    dispatch(getUrunler(katId)).then((data) => {
      setUrunler(data.data);
      setUrunlerFull(data.data);
    });
  }, [dispatch, common.selectedDilItems]);
  const [aramaTxt, setAramaTxt] = useState("");

  function filterUruns(txt) {
    setAramaTxt(txt);
    let urlist = [];
    if (txt == "" || txt == null) {
      setUrunler(urunlerFull);
    } else {
      urunlerFull.map((gg) => {
        if (extToLower(gg.adi).includes(extToLower(txt))) {
          urlist.push(gg);
        }
      });
      setUrunler(urlist);
    }
  }
  const [currentHeight, setCurrentHeight] = useState(0);
  useEffect(() => {
    let newHeight = 0;
    if (width > 1200) {
      newHeight = width / 9;
    } else if (width > 1000) {
      newHeight = width / 9;
    } else if (width > 800) {
      newHeight = width / 9;
    } else if (width > 600) {
      newHeight = width / 9;
    } else {
      newHeight = width / 4;
    }
    setCurrentHeight(newHeight);
  }, []);

  // function getUrunUcretsizHakDurumu(secilenUrun) {
  //   let ucretsizMi = false;

  //   let siparisUrunList = [];
  //   if (urun.siparisList.length > 0) {
  //     let newsiparisList = urun.siparisList.filter(
  //       (sip, sindex) =>
  //         new Date().getTime() + 21600000 >=
  //         new Date(sip.siparisTarihi).getTime()
  //     );
  //     if (newsiparisList.length > 0) {
  //       newsiparisList.map((sip) => {
  //         sip.urunListesi.map((ss) => siparisUrunList.push(ss));
  //       });
  //     }
  //   }
  //   let adisyonUrunListesiCopy = [...siparisUrunList];

  //   let urunUcretsizFiyatHak = {};
  //   adisyonUrunListesiCopy.map((tAd) => {
  //     if (tAd.ucretsizUrunHakki > 0) {
  //       tAd.ucretsizUrunFiyatIds.map((uuf) => {
  //         if (urunUcretsizFiyatHak[uuf]) {
  //           urunUcretsizFiyatHak[uuf].ucretsizUrunHakki +=
  //             tAd.adet * tAd.ucretsizUrunHakki;
  //         } else {
  //           urunUcretsizFiyatHak[uuf] = {
  //             ucretsizHakki: tAd.adet * tAd.ucretsizUrunHakki,
  //             kullanilan: 0,
  //             anaUrunFiyatId: tAd.urunFiyatId,
  //           };
  //         }
  //       });
  //     }
  //     if (tAd.isUcretsizHak && urunUcretsizFiyatHak[tAd.urunFiyatId]) {
  //       urunUcretsizFiyatHak[tAd.urunFiyatId].kullanilan += tAd.adet;
  //       // if (urunUcretsizFiyatHak[tAd.urunFiyatId].kullanilan > 0) {

  //       // } else {
  //       //   urunUcretsizFiyatHak[tAd.urunFiyatId].kullanilan = tAd.adet;
  //       // }
  //     }
  //   });

  //   if (secilenUrun.ucretsizUrunHakki > 0) {
  //     secilenUrun.ucretsizUrunFiyatIds.map((fiy) => {
  //       if (urunUcretsizFiyatHak[fiy]) {
  //         urunUcretsizFiyatHak[fiy].ucretsizUrunHakki +=
  //           1 * secilenUrun.ucretsizUrunHakki;
  //       } else {
  //         urunUcretsizFiyatHak[fiy] = {
  //           ucretsizHakki: 1 * secilenUrun.ucretsizUrunHakki,
  //           kullanilan: 0,
  //           anaUrunFiyatId: secilenUrun.fiyatId,
  //         };
  //       }
  //     });
  //   } else {
  //     if (urunUcretsizFiyatHak[secilenUrun.id]) {
  //       if (
  //         urunUcretsizFiyatHak[secilenUrun.id].ucretsizHakki >
  //         urunUcretsizFiyatHak[secilenUrun.id].kullanilan
  //       ) {
  //         let toplamKullanilan = 0;
  //         Object.keys(urunUcretsizFiyatHak).map(function (key, index) {
  //           let currentHak = urunUcretsizFiyatHak[key];
  //           if (
  //             currentHak.anaUrunFiyatId ==
  //             urunUcretsizFiyatHak[secilenUrun.id].anaUrunFiyatId
  //           ) {
  //             toplamKullanilan += currentHak.kullanilan;
  //           }
  //         });
  //         if (
  //           urunUcretsizFiyatHak[secilenUrun.id].ucretsizHakki >
  //           toplamKullanilan
  //         ) {
  //           urunUcretsizFiyatHak[secilenUrun.id].kullanilan += 1;
  //           ucretsizMi = true;
  //         }
  //       }
  //     }
  //   }
  //   return ucretsizMi;
  // }
  // const hizliSiparisVer = async (ur) => {
  //   console.log(ur);
  //  // let ucretsizMi = getUrunUcretsizHakDurumu(ur);

  //   let newData = {
  //     urunId: ur.id,
  //     urunAdi: ur.adi,
  //     foto: ur.foto,
  //     hazirlanmaSuresi: ur.hazirlanmaSuresi,
  //     alerjikEtkenler: ur.alerjikEtkenler,
  //     adet: 1,
  //     urunFiyatId: ur.fiyatId,
  //     fiyat: ur.fiyat,
  //     fiyatString: ur.fiyatString,
  //     siparisNotu: null,
  //     toplamTutar: ur.fiyat,
  //     siparisTipi: common.siparisTipi,
  //     masaId: common.masaId,
  //     birimAdi: ur.birimAdi,
  //     siparisTarihi: new Date(),
  //     menuIcindekiYanUrunlerString: ur.menuIcindekiYanUrunlerString,

  //     ucretsizUrunHakki: ur.ucretsizUrunHakki,
  //     ucretsizUrunFiyatIds: ur.ucretsizUrunFiyatIds,
  //    // isUcretsizHak: ucretsizMi,
  //   };
  //   dispatch(addNewUrun(newData));
  //   history.push("/siparisler/1");
  //   // alert.show(
  //   //   "Ürün sepete eklendi,onaylamak için sipariş ver butonuna tıklayınız.",
  //   //   { type: "success", timeout: 2000 }
  //   // );
  // };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader>
          {alerjikModalHeaderText}
          <div style={{ position: "absolute", right: 8, top: 1 }}>
            <i onClick={() => setModal(false)} class="bi bi-x-circle"></i>
          </div>
        </ModalHeader>
        <ModalBody>{alerjikModalText}</ModalBody>
      </Modal>
      <div className="mainUstContainer">
        <div className="mainLeftMenuBtn">
          <button
            className="solUstBtn btn btn-default"
            style={{ borderWidth: 0, padding: 0 }}
            onClick={() => {
              history.push("/islemler");
            }}
          >
            <i className="bi bi-list solUst"></i>
          </button>
        </div>
        <div className="mainRightSearchBtn">
          <input
            className="form-control araText"
            style={{ height: 41, paddingLeft: 30 }}
            // placeholder="Yemek Ara"
            placeholder={common.selectedDilItems.urunAraTxt}
            autoFocus={focus ? true : false}
            onChange={(e) => {
              filterUruns(e.target.value);
            }}
          />
          <i
            className="bi bi-search"
            style={{ position: "relative", top: -33, marginLeft: 7 }}
          ></i>
        </div>
        <div
          className="row"
          style={{
            marginBottom: 100,
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              className="col-md-12"
              style={{
                marginTop: 30,
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <h5 style={{ float: "left", fontWeight: "bold" }}>
                  {common.selectedDilItems.urunlerTxt}
                </h5>
              </div>
            </div>
            <div className="col-md-12">
              {urunler.length > 0 &&
                urunler.map((ur, index) => {
                  return (
                    <div
                      className="urunListeUrunBox"
                      style={{
                        width: width > 800 ? "31%" : "48%",
                        marginRight:
                          width > 800 ? "2%" : index % 2 == 0 ? "2%" : "0%",
                        marginLeft:
                          width > 800 ? "0%" : index % 2 == 1 ? "2%" : "0%",
                      }}
                    >
                      <button
                        className="urunListeUrunBoxButton"
                        style={{
                          width: "100%",
                          borderWidth: 0,
                          backgroundColor: "transparent",
                          // minHeight:currentHeight+40
                        }}
                        onClick={() => history.push("/urundetay/" + ur.id)}
                      >
                        <img
                          src={ur.foto}
                          style={{
                            width: "100%",
                            height: currentHeight,
                            objectFit: "cover",
                          }}
                        />
                      </button>
                      <div style={{ width: "100%", minHeight: 40 }}>
                        <div
                          style={{
                            textAlign: "left",
                            width: "70%",
                            // fontSize: 13,
                            fontSize: width > 320 ? 12 : 10,
                            float: "left",
                            fontWeight: "600",
                            marginTop: 3,
                          }}
                        >
                          {ur.adi}
                        </div>
                        <div
                          style={{
                            textAlign: "right",
                            width: "30%",
                            fontSize: width > 320 ? 12 : 10,
                            float: "right",
                            fontWeight: "500",
                            marginTop: 3,
                          }}
                        >
                          {ur.fiyat} TL
                        </div>
                      </div>
                      <div
                        className="col-md-12"
                        style={{
                          padding: 4,
                          backgroundColor: "transparent",
                          borderTop: "1px solid rgb(229 229 229)",
                        }}
                      >
                        {ur.alerjikEtkenler && (
                          <div
                            style={{
                              float: "left",
                              marginRight: 3,
                              fontSize: 25,
                              color: "red",
                            }}
                          >
                            <button
                              onClick={() => toogleSet(ur)}
                              className="urunListeUrunBoxButton"
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                color: "#f6192a",
                              }}
                            >
                              <span>
                                {/* <i class="bi bi-exclamation-triangle-fill"></i> */}
                                <i className="bi bi-person-heart"></i>
                              </span>
                            </button>
                          </div>
                        )}
                        <div
                          style={{
                            float: "left",
                            marginLeft: 3,
                            fontSize: 25,
                            // color: "red",
                          }}
                        >
                          <button
                            className="urunListeUrunBoxButton"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              color: "#f6192a",
                            }}
                            // onClick={() => hizliSiparisVer(ur)}
                            onClick={() => history.push("/urundetay/" + ur.id)}
                          >
                            <i class="bi bi-plus-circle"></i>
                          </button>
                        </div>
                        {ur.hazirlanmaSuresi && (
                          <div
                            style={{
                              float: "right",
                              fontSize: 11,
                              marginRight: 0,
                              color: "#b0780e",
                              marginTop: 13,
                            }}
                          >
                            <i
                              class="bi bi-clock"
                              style={{ color: "#b0780e", paddingRight: 3 }}
                            ></i>
                            {ur.hazirlanmaSuresi}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Urunler;
