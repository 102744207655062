import axios from "axios";
import qs from "qs";



export const getAnaSayfaItems = (dataVM) => async (dispatch) => {
  const response = await axios.get("/api/ClientCommon/GetAnaSayfaItems");
  const data = await response.data;

  return data;
};

export const getUrunGrubus = (dataVM) => async (dispatch) => {
  const response = await axios.get("/api/ClientCommon/GetUrunGrubus");
  const data = await response.data;
  return data;
};

export const getUrunler = (katId) => async (dispatch) => {
  const response = await axios.get(
    "/api/ClientCommon/GetUrunler?katId=" + katId
  );
  const data = await response.data;
  return data;
};

export const getUrunDetay = (urunId) => async (dispatch) => {
  const response = await axios.get(
    "/api/ClientCommon/GetUrunDetay?urunId=" + urunId
  );
  const data = await response.data;
  return data;
};

export const setSiparisEkle = (dataVM) => async (dispatch) => {
  const response = await axios.post("/api/ClientCommon/SiparisEkle", dataVM);
  const data = await response.data;
  return data;
};
