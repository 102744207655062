import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import {
    Container,
    Button,
    Spinner,
    Modal, ModalFooter, ModalHeader, ModalBody
} from "reactstrap";
import { setGarsoniyeBildirim, setHizmetTipiAdi } from '../store/reducers/commonSlice';
import { useAlert } from "react-alert";

function GarsoniyeSecim(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const common = useSelector((state) => state.common);
    const alert = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [garsoniyeModalOpen, setGarsoniyeModalOpen] = useState(false);
    const toggle = () => setGarsoniyeModalOpen(!garsoniyeModalOpen);

    function hizmetTipiSec(tipAdi) {
        setSubmitting(true);
        if (tipAdi == "garsoniye") {

        }
        dispatch((setHizmetTipiAdi(tipAdi)));
        if (tipAdi == "garsoniye") {
            dispatch(setGarsoniyeBildirim(common.masaId))
        }
    }
    return (
        <>

            <Modal isOpen={garsoniyeModalOpen} toggle={toggle} centered={true}>
                <ModalHeader>
                    {/* Doğrulama */}
                    <div style={{ position: "absolute", right: 8, top: 1 }}>
                        <i
                            onClick={() => setGarsoniyeModalOpen(false)}
                            class="bi bi-x-circle"
                        ></i>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {common.selectedDilItems.garsoniyeHizmetTipiValidationTxt}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        onClick={() => {
                            hizmetTipiSec("garsoniye");
                            toggle();
                        }}
                    >
                        {/* Evet */}
                        {common.selectedDilItems.evetTxt}
                    </Button>
                    <Button onClick={() => toggle()}>
                        {" "}
                        {common.selectedDilItems.hayirTxt}
                    </Button>
                </ModalFooter>
            </Modal>


            <div
                style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#f8f6f7",
                    height: window.innerHeight - 1 - 25,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#f8f6f7",
                    }}
                >
                    <img
                        src={"./assets/giris.png"}
                        style={{
                            width: "100%",
                            maxWidth: window.width,
                            height: window.innerHeight / 2,
                            objectFit: "contain",
                        }}
                    />
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: "#282828",
                            color: "#fff",
                            width: "80%",
                            maxWidth: 250,
                            marginBottom: 10,
                            fontWeight: "600",
                        }}
                        disabled={submitting}
                        onClick={() => { hizmetTipiSec("masadan") }}
                    >
                        {common.selectedDilItems.masadanHizmetTipiTxt}
                        {/* Masadan Sipariş Ver */}
                    </Button>

                    <Button
                        style={{
                            backgroundColor: "#f9172f",
                            color: "#fff",
                            width: "80%",
                            maxWidth: 250,
                            marginBottom: 10,
                            fontWeight: "600",
                        }}
                        disabled={submitting} onClick={() => {
                            setGarsoniyeModalOpen(true)
                        }}
                    >
                        {common.selectedDilItems.garsoniyeHizmetTipiTxt}
                        {/* Garsoniye Hizmeti Talep Et */}
                    </Button>
                </div>

                <div
                    style={{
                        width: "90%",
                        marginLeft: "5%",
                        marginTop: "25px",
                        color: "#989697",
                        fontSize: 12,
                        textAlign: "center",
                    }}
                >
                    <i class="bi bi-info-circle" style={{ color: "#f9172f" }}></i>
                    <span style={{ marginLeft: 5 }}>
                        {common.selectedDilItems.hizmetTipiAciklamaTxt}
                        {/* Garsoniye hizmeti ya da masadan hizmet ile ile ilgili açıklama gelecek. */}
                    </span>
                </div>

            </div>
        </>
    );
}
export default GarsoniyeSecim;
