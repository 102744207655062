import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./reducers/userSlice";
import commonReducer from "./reducers/commonSlice";
import urunReducer from "./reducers/urunSlice";
const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    user: userReducer,
    common: commonReducer,
    urun: urunReducer,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
  */
  if (action.type === "auth/user/userLoggedOut") {
    //state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
